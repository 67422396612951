<template>
  <div v-if="graphSelectedNode"
       class="sidebar-node">

    <div class="node-infos">
      <div class="info-row">
        <h3 class="info-value info-value--name">{{graphSelectedNode.name}}</h3>
        <span class="info-value info-value--node-type">{{$t(`pages.graph.sidebarNode.nodeTypes.${graphSelectedNode.nodeType.toLowerCase()}`)}}</span>
        <icon-node-type :node="graphSelectedNode"
                        :nodeClass="nodeClass" />
      </div>
      <div class="info-row">
        <div class="info-value info-value--uuid">{{graphSelectedNode.uuid || graphSelectedNode.device}}</div>
      </div>
      <div class="info-row">
        <div class="info-value info-value--description">{{graphSelectedNode.description || $t('pages.graph.sidebarNode.emptyDescription')}}</div>
      </div>
    </div>

    <div class="node-actions">
      <div v-if="isPlug && canAddDevice && user.featuresRights.device.WRITE"
           class="o-button o-button--shadows o-button--hover o-button--bordered t_node-actions--addDevice"
           @click="openAddDeviceModal">ADD DEVICE</div>
      <div v-if="isNode && !isPlug && user.featuresRights.graph.WRITE"
           class="o-button o-button--shadows o-button--hover o-button--bordered t_node-actions--addNode"
           @click="openCreateNodeModal">ADD NODE</div>
      <div v-if="isNode && user.featuresRights.graph.WRITE"
           class="o-button o-button--shadows o-button--hover o-button--bordered t_node-actions--editNode"
           @click="openEditNodeModal">EDIT NODE</div>
      <div v-if="isNode &&  user.featuresRights.graph.WRITE"
           class="o-button o-button--shadows o-button--hover o-button--negative t_node-actions--deleteNode"
           @click="deleteNode">DELETE NODE</div>
      <div v-if="isDevice && user.featuresRights.device.WRITE"
           class="o-button o-button--shadows o-button--hover o-button--negative t_node-actions--deleteDevice"
           @click="deleteNode">DELETE DEVICE</div>
    </div>
    <CreateNodeModal :show="showCreateNodeModal"
                     :node="graphSelectedNode"
                     :edit="editNode"
                     @hide="closeModal" />
    <AddDeviceModal :show="showAddDeviceModal"
                    :plug="graphSelectedNode"
                    @hide="closeModal" />
  </div>
</template>

<script>
// Models
import Node from 'models/topology/node';
import Plug from 'models/topology/plug';
import Device from 'models/device';

// Components
import IconNodeType from '@/app/components/topology/icon-node-type.vue';
import CreateNodeModal from '@/app/pages/data/components/create-node-modal.vue';
import AddDeviceModal from '@/app/pages/data/components/add-device-modal.vue';

export default {
  name: 'SidebarNode',
  components: {
    IconNodeType,
    CreateNodeModal,
    AddDeviceModal,
  },
  data() {
    return {
      showCreateNodeModal: false,
      editNode: false,
      showAddDeviceModal: false,
    };
  },
  computed: {
    graphSelectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    isNode() {
      return this.graphSelectedNode instanceof Node;
    },
    isPlug() {
      return this.graphSelectedNode instanceof Plug;
    },
    isDevice() {
      return this.graphSelectedNode instanceof Device;
    },
    canAddDevice() {
      return !this.graphSelectedNode.next || this.graphSelectedNode.next.length === 0;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    openCreateNodeModal() {
      this.showCreateNodeModal = true;
    },
    openEditNodeModal() {
      this.editNode = true;
      this.showCreateNodeModal = true;
    },
    openAddDeviceModal() {
      this.showAddDeviceModal = true;
    },
    closeModal() {
      this.editNode = false;
      this.showCreateNodeModal = false;
      this.showAddDeviceModal = false;
    },
    async deleteNode() {
      try {
        await this.$q.dialog({
          title: `Delete this node?`,
          ok: true,
          cancel: true,
          color: 'black',
        });
        try {
          await this.$store.dispatch('topology/delete', { node: this.graphSelectedNode, force: false });
          this.$q.notify({ message: `${this.graphSelectedNode.name} successfully deleted`, type: 'positive', position: 'bottom-left' });
        } catch (error) {
          const {
            data: { errorCode },
          } = error.response;
          if (errorCode === 'topologyForbiddenUserNodes' || errorCode === 'topologyForbiddenDevicePlugs') {
            this.confirmDelete(errorCode);
          } else
            this.$q.notify({ message: `Error when deleting ${this.graphSelectedNode.name}`, type: 'negative', position: 'bottom-left' });
        }
      } catch (error) {
        // when click on dialog cancel button
      }
    },
    async confirmDelete(errorCode) {
      if (errorCode === 'topologyForbiddenUserNodes') {
        await this.$q.dialog({
          title: `Other users are related to this node, you can't delete it.`,
          ok: false,
          cancel: false,
          color: 'negative',
        });
      } else if (errorCode === 'topologyForbiddenDevicePlugs') {
        try {
          await this.$q.dialog({
            title: `Devices are installed under this node, are you sure you want to delete it?`,
            ok: true,
            cancel: true,
            color: 'black',
          });
          try {
            await this.$store.dispatch('topology/delete', { node: this.graphSelectedNode, force: true });
            this.$q.notify({ message: `${this.graphSelectedNode.name} successfully deleted`, type: 'positive', position: 'bottom-left' });
          } catch (error) {
            this.$q.notify({ message: `Error on ${this.graphSelectedNode.name} deletion`, type: 'negative', position: 'bottom-left' });
          }
        } catch (error) {
          // When click on dialog cancel button
        }
      }
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.sidebar-node
  display flex
  flex-direction column
  height 100%
  .node-infos
    display flex
    flex 1
    flex-direction column
    margin-top $space-2
    padding $space-2
    .info-row
      display flex
      align-items flex-end
      margin-bottom $space-2
    .info-value
      &--name
        margin 0
        font-weight 700
        font-size $fs-big
        line-height 1
      &--node-type
        margin-right $space-1
        margin-left $space-2
        text-transform uppercase
        font-size $fs-h3
      &--uuid
        color darken($grey, 20)
        font-size $fs-h3
  .node-actions
    display flex
    justify-content flex-start
    padding $space-2
    background-color white
    .o-button
      display flex
      justify-content center
      align-items center
      margin-right $space-2
      width fit-content
</style>