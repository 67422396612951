<template>
  <div class="building-control">
    <input v-model="searchValue"
           type="text"
           class="o-input o-input--faded"
           :placeholder="$t('components.buildingSelection.filterPlaceholder')">
    <div class="building-list">
      <BuildingCheckbar v-for="b in buildingsFiltered"
                        :key="b.uuid"
                        :building="b"
                        :selected="isBuildingSelected(b)"
                        :openFloorSelection="floorDrawerState[b.uuid]"
                        :selection="getFloorPercentageSelection(b)"
                        class="building-item"
                        @mainClick="toggleSelect(b)"
                        @secondClick="toggleFloorSelectionDrawer(b)" />
    </div>
  </div>
</template>

<script>
import BuildingSelectionMixin from '@/app/mixins/building-selection';
import BuildingCheckbar from '@/app/components/buildings/building-checkbar.vue';

export default {
  name: 'BuildingSelection',
  components: {
    BuildingCheckbar,
  },
  mixins: [BuildingSelectionMixin],
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    buildings() {
      return this.$store.state.building.collection;
    },
    buildingsFiltered() {
      if (this.searchValue === '') return this.buildings;
      return this.buildings.filter(b => b.name.toLowerCase().includes(this.searchValue.toLowerCase()));
    },
  },
  mounted() {
    document.addEventListener('keydown', evt => (evt.key === 'Escape' ? this.resetFloorDrawersState() : ''));
  },
  methods: {
    toggleSelect(building) {
      this.resetFloorDrawersState();
      this.toggleBuildingSelect(building);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.building-control
  padding $space-2 $space-1
  width 100%
  .o-input
    margin-bottom 16px
    width 100%

.building-list
  display flex
  flex-direction column
  .building-item
    margin-bottom $space-little
    &:last-child
      margin-bottom 0

.building-control__selection
  display flex
  margin-top 16px
  color $dark
</style>
