<template>
  <div style="height: 100%">
    <q-layout ref="layout"
              class="home">
      <q-layout-drawer v-if="$q.platform.is.desktop"
                       :width="sidebarInWidth"
                       :value="sidebarInState"
                       class="home__sidebar home__sidebar--left no-transition"
                       side="left">
        <SidebarIn :sidebarState="sidebarInState"
                   @toggleSidebar="toggleSidebar" />
      </q-layout-drawer>
      <q-page-container class="home__center"
                        :class="homeCenterClass">
        <div class="viewer">
          <globalViewer v-if="!currentBuilding && visualisationMode === '2d'" />
          <graphViewer v-if="visualisationMode === 'graph'" />
          <planViewer v-if="currentBuilding && visualisationMode === '2d'" />
          <time-navigation v-if="currentOapp && viewerMode === 'navigation' && $q.platform.is.desktop" />
        </div>
        <navigation-tabs v-if="$q.platform.is.mobile" />
        <SidebarOut v-if="$q.platform.is.desktop"
                    class="home__sidebar home__sidebar--right" />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import globalViewer from '@/app/pages/data/views/global-viewer.vue';

import SidebarIn from '@/app/pages/data/views/sidebar-in.vue';
import SidebarOut from '@/app/pages/data/views/sidebar-out.vue';
import NavigationTabs from '@/app/components/layout/mobile-navigation-tabs.vue';

export default {
  name: 'Home',
  components: {
    SidebarIn,
    globalViewer,
    // Lazy-loaded components
    graphViewer: () => import('@/app/pages/data/views/graph-viewer.vue'),
    planViewer: () => import('@/app/pages/data/views/plan-viewer.vue'),
    TimeNavigation: () => import('@/app/views/building-viewer/components/time-navigation.vue'),
    SidebarOut,
    NavigationTabs,
  },
  data() {
    return {
      sidebarInWidth: 250,
    };
  },
  computed: {
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    ...mapState('ui', {
      viewerMode: state => state.viewerMode,
      visualisationMode: state => state.visualisationMode,
      sidebarInState: state => state.home.sidebarIn,
      sidebarOutState: state => state.home.sidebarOut,
    }),
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    visualisationControlClass() {
      return { 'visualisation-control--sided': this.currentBuilding && this.visualisationMode === '2d' };
    },
    homeCenterClass() {
      return {
        'home__center--sr-hidden': this.sidebarOutState === 'hidden',
        'home__center--sr-small': this.sidebarOutState === 'small',
        'home__center--sr-medium': this.sidebarOutState === 'medium',
        'home__center--sr-large': this.sidebarOutState === 'large',
      };
    },
  },
  watch: {
    visualisationMode(mode) {
      if (mode === 'graph') {
        this.setSidebarInState(false);
        this.setSidebarOutState('medium');
      }
      if (mode === '2d') {
        this.setSidebarInState(true);
        this.setSidebarOutState('small');
      }
    },
    sidebarInState(opened) {
      if (opened) this.sidebarInWidth = 250;
      if (!opened) this.sidebarInWidth = 239;
    },
  },
  created() {
    this.setViewerMode('navigation');
    if (this.visualisationMode === 'graph') {
      this.setSidebarInState(false);
      this.setSidebarOutState('medium');
    } else {
      this.setSidebarInState(true);
      this.setSidebarOutState('small');
    }
  },
  methods: {
    ...mapMutations('ui', {
      setViewerMode: 'SET_VIEWER_MODE',
      setSidebarInState: 'SET_SIDEBARIN_STATE',
      setSidebarOutState: 'SET_SIDEBAROUT_STATE',
    }),
    toggleSidebar() {
      this.setSidebarInState(!this.sidebarInState);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.home
  min-height auto
  height 100%
  .home__sidebar.no-transition >>> aside
    transform translateX(0px)
  .home__sidebar--left >>> aside
    overflow visible // to see the drawer
  .home__sidebar--right
    z-index 2
  .home__center
    z-index 1
    padding-right 350px
    width 100%
    height 100%
    transition padding-right 0.3s ease
    &--sr-hidden
      padding-right 0
    &--sr-small
      padding-right 350px
    &--sr-medium, &--sr-large
      padding-right calc((100vw / 3))
    @media screen and (max-width: 500px), (max-height: 500px)
      overflow hidden
      padding-right 0
    .viewer
      position relative
      height 100%
      background-color $silver
    .visualisation-control
      position absolute
      top $space-2
      left @top
      z-index 2
      transition left 0.3s ease
      &--sided
        left 110px
</style>
