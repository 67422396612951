<template>
  <div :style="containerStyle"
       class="filter-selection">
    <transition name="fade">
      <Drawer v-if="drawerState"
              v-on-clickaway="away"
              posY="0px"
              class="tags-drawer"
              side="left">
        <PropSelection :properties="Object.keys(properties)" />
      </Drawer>
    </transition>
    <div v-if="!objectIsEmpty(selectedFilters)"
         class="filter-list">
      <div v-for="p in Object.keys(properties)"
           v-if="propIsSelected(p)"
           :key="p"
           class="filter">
        <h4 class="filter__title">
          {{ p }}
        </h4>
        <div class="filter__values">
          <button v-for="v in properties[p]"
                  :key="v"
                  :class="valueClass(p, v)"
                  class="o-button o-button--shadows o-button--hover"
                  @click="toggleValueSelect(p, v)">
            <span class="o-button__content">{{$t(`pages.buildingConfiguration.properties.type.${v}`)}}</span>
            <span class="o-button__side-info">{{getSpaceCount(p, v)}}</span>
          </button>
        </div>
      </div>
    </div>
    <label v-else
           class="o-label">
      {{$t('components.filterSelection.noFilters')}}
    </label>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

import Utils from '@/app/mixins/utils';

import Drawer from '@/app/components/ui/drawer.vue';
import PropSelection from '@/app/pages/data/components/prop-selection.vue';

export default {
  name: 'FilterSelection',
  directives: { onClickaway: onClickaway },
  components: {
    Drawer,
    PropSelection,
  },
  mixins: [Utils],
  data() {
    return {
      drawerState: false,
    };
  },
  computed: {
    selectedFilters() {
      return this.$store.state.selections.filters;
    },
    properties() {
      return this.$store.state.zones.properties.collection;
    },
    buttonClass() {
      return {
        'o-button--black': this.drawerState,
      };
    },
    containerStyle() {
      return Object.keys(this.properties).length ? '' : 'padding-bottom: 30px';
    },
  },
  mounted() {
    document.addEventListener('keydown', evt => (evt.key === 'Escape' ? (this.drawerState = false) : ''));
    this.$store.dispatch('zones/properties/fetch');
  },
  methods: {
    toggleDrawer() {
      this.drawerState = !this.drawerState;
    },
    propIsSelected(prop) {
      return prop in this.selectedFilters;
    },
    valueIsSelected(prop, value) {
      return this.propIsSelected(prop) && this.selectedFilters[prop].includes(value);
    },
    valueClass(prop, value) {
      return {
        'o-button--black': this.valueIsSelected(prop, value),
      };
    },
    toggleValueSelect(prop, value) {
      if (this.valueIsSelected(prop, value)) this.$store.commit('selections/removeFilter', { key: prop, value });
      else this.$store.commit('selections/addFilter', { key: prop, value });
      this.$store.dispatch('oapp/fetchCurrentOappData');
      this.$emit('input', this.selectedFilters);
    },
    away() {
      this.drawerState = false;
    },
    getSpaceCount(type, property) {
      return this.$store.getters['zones/properties/selectionPropertiesSpaceList']({ [type]: property }).length;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.filter-selection
  position relative
  width 100%
  .o-label
    display block
    margin 0
    padding $space-2
    font-weight $fw-light
    font-size $nfs-body
  .tags-drawer
    position absolute
    top -40px
    left 100%
    transition all 0.3s ease
  .filter-list
    display flex
    flex-direction column
    min-height 40px
    .filter
      display flex
      flex-direction column
      padding 16px
      padding-bottom 8px
      .filter__title
        margin 0
        margin-bottom 16px
        font-weight $fw-regular
        font-size $nfs-h3
        line-height 1
      .filter__values
        display flex
        flex-wrap wrap
        .o-button
          display flex
          margin-right 8px
          margin-bottom 8px
          padding 0
          height auto
          border-radius $border-radius-rounded
          background-color white
          font-weight $fw-regular
          .o-button__content
            padding $space-little $space-1
            border-top-left-radius $border-radius-rounded
            border-bottom-left-radius $border-radius-rounded
          .o-button__side-info
            padding $space-little $space-2
            height 100%
            border-top-right-radius $border-radius-rounded
            border-bottom-right-radius $border-radius-rounded
            background-color $light
            color $dark
          &:last-child
            margin-right 0
        .o-button--black
          background-color white !important
          .o-button__content
            background-color $black

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
