<template>
  <div class="oapp-item">
    <div class="oapp-item__header">
      <div :style="oappIconStyle"
           class="oapp-icon"
           @click="toggleExtendOapp">
        <component v-if="!fetching || !isCurrentOapp(id)"
                   :is="icon"
                   class="icon" />
        <Spinner v-if="fetching && isCurrentOapp(id)"
                 class="spinner" />
      </div>
      <div class="oapp-main">
        <div class="oapp-info"
            :class="'t_oapp-info--' + name"
             @click="toggleExtendOapp">
          <span class="oapp-name"
                :class="oappNameClass">
            {{ displayedName }}
          </span>
          <span v-if="!fetching"
            class="oapp-value">
            {{ value ? value.label : type !== 'custom' ? $t('oapps.commons.noData') : '' }}
          </span>
        </div>
        <q-icon v-if="!collapsed"
                name="close"
                class="oapp-close"
                size="30px"
                @click.native="toggleExtendOapp" />
      </div>
    </div>
    <collapseContainer v-if="hasSlot"
                       :pose="collapsed ? 'hidden' : 'visible'"
                       class="collapse-container">
      <slot />
      <!-- <div v-if="$q.platform.is.desktop"
           class="oapp-item__footer">
        <div class="o-button o-button--shadows o-button--black o-button--hover"
             @click="emitDashboardClick">
          {{ dashboardExpanded ? $t('oapps.commons.close') : $t('oapps.commons.more') }}
        </div>
      </div> -->
    </collapseContainer>
  </div>
</template>

<script>
import posed from 'vue-pose';
import { mapState } from 'vuex';
import chroma from 'chroma-js';
import DebounceMixin from '@/app/mixins/debounce';
import Spinner from '@/app/components/ui/spinner.vue';

export default {
  name: 'OappItem',
  components: {
    Spinner,
    collapseContainer: posed.div({
      visible: {
        height: 'auto',
        applyAtEnd: { overflow: 'visible' },
      },
      hidden: {
        height: '0px',
        applyAtStart: { overflow: 'hidden' },
      },
    }),
  },
  mixins: [DebounceMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    displayedName: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    dashboardExpanded: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    limits: {
      type: Object,
      default: () => ({ min: 0, max: 0 }),
    },
  },
  data() {
    return {
      colorScale: null,
    };
  },
  computed: {
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    fetching() {
      return this.$store.state.oapp.fetching;
    },
    ...mapState('ui', ['colorScales']),
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    hasSlot() {
      return this.$slots.default;
    },
    oappIconStyle() {
      return {
        backgroundColor: this.colorScale && this.value && this.value.value ? this.colorScale(this.value.value) : 'white',
      };
    },
    oappNameClass() {
      return {
        'oapp-name--collapsed': !this.collapsed,
      };
    },
  },
  watch: {
    value: {
      handler(val) {
        this.colorScale = chroma.scale(this.colorScales[this.type]).domain([this.limits.min, this.limits.max]);
        this.$store.commit('oapp/update', { oappId: this.id, data: { color: val ? this.colorScale(val.value) : 'white' } });
      },
      deep: true,
    },
  },
  mounted() {
    this.colorScale = chroma.scale(this.colorScales[this.type]).domain([this.limits.min, this.limits.max]);
  },
  methods: {
    isCurrentOapp(oappId) {
      return this.currentOapp.id === oappId;
    },
    toggleExtendOapp() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) this.$store.commit('oapp/setCurrentOapp', undefined);
      else this.$store.commit('oapp/setCurrentOapp', this.id);
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    emitDashboardClick() {
      this.$emit('dashboardClick');
    },
    emitCloseClick() {
      this.collapsed = true;
      this.$store.commit('oapp/setCurrentOapp', undefined);
      this.cleanCanvas();
    },
    cleanCanvas() {
      this.currentFloors.forEach((f, idx) => {
        const canvas = document.getElementById(`canvas${idx}`);
        if (canvas) canvas.getContext('2d').clearRect(0, 0, canvas.clientWidth, canvas.height);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.oapp-item
  display flex
  flex-direction column
  margin-bottom $space-tiny
  background-color white
  &:first-child
    margin-top $space-tiny
  .oapp-item__header
    display flex
    justify-content flex-start
    align-items center
    width 100%
    border-bottom $grid-border
    cursor pointer
    .oapp-icon
      display flex
      justify-content center
      align-items center
      padding 8px
      border-right $grid-border
      cursor pointer
      .icon
        width 45px
        height @width
      .spinner
        width 45px
        height @width
    .oapp-main
      display flex
      justify-content space-between
      align-items center
      width 100%
      height 61px
      user-select none
      .oapp-info
        display flex
        flex 1
        flex-direction column
        padding $space-2 $space-1
        height 100%
      .oapp-name
        display flex
        align-items center
        padding-left $space-2
        height 100%
        text-transform uppercase
        font-weight 700
        font-size $fs-h2
        transition all 0.3s ease
        &--collapsed
          height fit-content
          color gray
          font-weight 400
          font-size $fs-h3
          line-height 1
      .oapp-value
        margin-left $space-2
        font-weight 300
        font-size $fs-h1
      .oapp-close
        margin-right 10px
        cursor pointer
  .oapp-item__main
    height 0
  .oapp-item__footer
    display flex
    justify-content center
    padding 16px
  .oapp__slot
    min-height 400px
    background-color #f7f7f5

// VUE.js transition classes
.collapse-transition
  overflow hidden
  transition max-height 0.3s ease 0.2s

.fade-enter-active, .fade-leave-active
  max-height 2000px

.fade-enter, .fade-leave-to
  max-height 0px
  transition max-height 0.3s cubic-bezier(0, 1, 0, 1) 0s
  @media screen and (max-width: 500px)
    transition none
</style>
