<template>
  <Field label="Country"
         hint="search the country"
         :errorMessage="errorMessage"
         :required="required">
    <q-input v-model="value"
             class="country-input">
      <q-autocomplete :staticData="countries"
                      :minCharacters="0"
                      @selected="emitCountryCode" />
    </q-input>
  </Field>
</template>

<script>
import Field from '@/app/components/inputs/field.vue';

export default {
  name: 'CountrySelect',
  components: {
    Field,
  },
  model: {
    props: 'value',
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    countries() {
      return { field: 'value', list: this.$store.state.countries.collection };
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value);
      this.$emit('selected', this.getCountryAlpha2(this.value));
    },
  },
  created() {
    if (this.countries.length === 0) this.$store.dispatch('countries/fetch');
  },
  methods: {
    emitCountryCode(country) {
      this.$emit('selected', country.alpha2);
    },
    getCountryAlpha2(country) {
      const existingCountry = this.countries.list.find(c => c.label.toLowerCase() === country.toLowerCase());
      if (!existingCountry) return '';
      else return existingCountry.alpha2;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.country-input
  padding 0
  & >>> input
    padding $space-1
    height $space-3
    border 1px solid black
    border-radius $border-radius-smooth
    background-color white
    font-size $fs-h2
    &:focus
      box-shadow $shadow-elevation-2
</style>