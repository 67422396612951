<template>
  <div>
    <q-modal v-model="sumModal"
             class="widget-modal"
             @show="initiateModal"
             @hide="closeModal">
      <div class="modal__header">
        <h4 class="modal-title">{{!editMode ? $t('pages.graph.sidebarSum.createSum'): $t('pages.graph.sidebarSum.editSum')}}</h4>
        <q-icon name="mdi-close"
                class="close-button"
                size="45px"
                @click.native="closeModal" />
      </div>
      <div class="modal__content">
        <text-field v-model="sum.name"
                    class="sum-name"
                    :label="$t('pages.graph.sidebarSum.nameLabel')"
                    :hint="!editMode ? $t('pages.graph.sidebarSum.nameHint') : ''"
                    required />
        <div class="device-select--header">
          <div class="text-field"
               :class="textFieldClass">
            <span class="text-field__label"
                  :class="labelClass">
              {{$t('pages.graph.sidebarSum.deviceLabel')}}
              <span class="text-field__required">*</span></span>
            <span class="text-field__hint">{{$t('pages.graph.sidebarSum.deviceHint')}}</span>
          </div>
        </div>
        <q-select v-if="!editMode"
                      v-model="filterSelect"
                      :floatLabel="$t('pages.graph.sidebarSum.labelProfile')"
                      radio
                      :options="selectOptions"/>
        <div class="device-select">

            <div v-for="device in devicesUnderNode"
:key="device.uuid">
              <span class="device-select--name">{{device.name}}</span>
              <div v-for="(profile, idx) in device.deviceType.filteredProfiles"
:key="profile.name"
                  class="sum-profile"
                 >
                <div class='profile-name'>
                  {{device.deviceType.filteredProfiles[idx].name
                  }}
                </div>
                <div>
                  <span class="o-button o-button--hover o-button--squared"
                :class="profileIsSelected(profile, 'add')"
                @click="onSetOperator('add', profile)">{{$t('pages.graph.sidebarSum.add')}}</span>
                  <span  class="o-button o-button--hover o-button--squared"
                :class="profileIsSelected(profile, 'subtract')"
                @click="onSetOperator('subtract', profile)">{{$t('pages.graph.sidebarSum.subtract')}}</span>
                  <span  class="o-button o-button--hover o-button--squared"
                :class="profileIsSelected(profile, 'donotuse')"
                @click="onSetOperator('donotuse', profile)">{{$t('pages.graph.sidebarSum.donotuse')}}</span>
                </div>
              </div>
              </div>
        </div>
      </div>
      <div class="modal__footer">
        <div class="buttons">
          <q-btn class="o-button o-button--shadows o-button--black o-button--hover"
                 :class="createButtonClass"
                 :label="!editMode ? $t('pages.graph.sidebarSum.create') : $t('pages.graph.sidebarSum.edit')"
                 @click="() => !editMode ? (sum.name && createSum()) : updateSum()" />
        </div>
      </div>
    </q-modal>
  </div>
</template>

<script>

import Device from 'models/device';

import TextField from '@/app/components/inputs/text-field.vue';
export default {
  name: 'SumCreation',
  components: {
    TextField,
  },
  props: {
    sumModal: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    sum: {
      type: Object,
      default: () => ({
        name: '',
      }),
    },
  },
  data() {
    return {
      filterSelect: '',
      filterSearch: '',
      paginationControl: { rowsPerPage: 0, page: 1 },
      devicesUnderNode: [],
      selectOptions: [],
      operator: 'add',
      columns: [
        {
          name: 'uuid',
          required: true,
          label: 'UUID',
          align: 'left',
          field: 'name',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    currentSumId() { return this.$store.state.sum.currentSumId; },
    graphSelectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    canCreate() {
      return this.sum.name !== '';
    },
    createButtonClass() {
      return {
        'o-button--disabled': !this.canCreate,
      };
    },
  },
  watch: {
    filterSelect() {
      this.addFilteredProfilesToDevices();
    },
    filterSearch() {
      this.addFilteredProfilesToDevices();
    },
  },
  methods: {
    profileIsSelected(profile, operation) {
      return {
        ' o-button--dark': profile.op === operation
      };
    },
    onSetOperator(operator, profile) {
      profile.op = operator;
    },
    async createSum() {
      const sumItems = [];
      this.devicesUnderNode.forEach(device => {
        device.deviceType.filteredProfiles.forEach(profile => {
          if (profile.op === 'add') {
            sumItems.push({ deviceUuid: device.uuid, operator: 0, profileId: profile.id });
          } else if (profile.op === 'subtract') {
            sumItems.push({ deviceUuid: device.uuid, operator: 1, profileId: profile.id });
          }
        });
      });
      try {
        await this.$store.dispatch('sum/addSum', { name: this.sum.name, nodeUuid: this.graphSelectedNode.uuid, sumItems });
        this.$q.notify({ message: `Sum successfully added`, type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({ message: `Error when adding sum`, type: 'negative', position: 'bottom-left' });
      } finally {
        this.sumModal = false;
      }
    },
    async updateSum() {
      try {
        await this.$store.dispatch('sum/patchSum', { sumId: this.sum.id, name: this.sum.name });
        for (const device of this.devicesUnderNode) {
          for (const profile of device.deviceType.filteredProfiles) {
            /* eslint-disable no-await-in-loop */
            // I don't think all operations are independant, thus we can't mess up with the order, and have
            // to wait for every operation to be done before firing the next one.
            const sumItem = this.sum.sumItems.find(
              sumItem => sumItem.profile.id === profile.id && sumItem.devicePlug.device === device.uuid
            );
            if (!sumItem) {
              if (profile.op !== 'donotuse') {
                await this.$store.dispatch('sum/addSumItem', {
                  sumId: this.sum.id,
                  deviceUuid: device.uuid,
                  profileId: profile.id,
                  operator: profile.op === 'add' ? 0 : 1,
                });
              }
            } else {
              if (profile.op === 'donotuse') {
                await this.$store.dispatch('sum/deleteSumItem', { sumId: this.sum.id, sumItemId: sumItem.id });
              } else {
                await this.$store.dispatch('sum/patchSumItem', {
                  sumId: this.sum.id,
                  sumItemId: sumItem.id,
                  operator: profile.op === 'add' ? 0 : 1,
                });
              }
            }
            /* eslint-enable no-await-in-loop */
          }
        }
        this.$q.notify({ message: `Sum successfully updated`, type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({ message: `Error when updating sum`, type: 'negative', position: 'bottom-left' });
      } finally {
        this.sumModal = false;
      }
    },
    addFilteredProfilesToDevices() {
      this.devicesUnderNode = this.devicesUnderNode.map(device => ({
        ...device,
        deviceType: {
          ...device.deviceType,
          filteredProfiles: device.deviceType.profiles
            .filter(profile => this.profileShouldBeIncluded(profile))
            .map(profile => {
              if (!this.editMode) return { ...profile, op: 'donotuse' };
              const sumItem = this.sum.sumItems.find(
                sumItem => sumItem.profile.id === profile.id && sumItem.devicePlug.device === device.uuid
              );
              if (!sumItem) return { ...profile, op: 'donotuse' };
              return { ...profile, op: sumItem.operator === 0 ? 'add' : 'subtract' };
            }),
        },
      }));
    },
    profileShouldBeIncluded(profile) {
      return this.filterSelect === profile.unit && (this.filterSearch === '' || profile.name.includes(this.filterSearch));
    },
    onSearchInput(val) {
      this.filterSearch = val;
    },
    initiateModal() {
      const devicePlugs = [];
      const addDevicesUnderTopo = (node, devicePlugs) => {
        if (node instanceof Device && node.deviceType !== null) devicePlugs.push(node);
        if (node.next) {
          node.next.forEach(next => addDevicesUnderTopo(next, devicePlugs));
        }
      };
      addDevicesUnderTopo(this.graphSelectedNode, devicePlugs);
      this.devicesUnderNode = devicePlugs;
      this.initiateSelectOptions();
      this.addFilteredProfilesToDevices();
    },
    initiateSelectOptions() {
      const deviceTypes = [];
      for (const device of this.devicesUnderNode) {
        const find = deviceTypes.find(deviceType => deviceType.name === device.deviceType.name);
        if (!find && device.deviceType !== null) deviceTypes.push(device.deviceType);
      }
      const units = [];
      for (const deviceType of deviceTypes) {
        for (const profile of deviceType.profiles) {
          //add all unit not empty of profiles to select
          const find = units.find(unit => profile.unit === unit.value);
          if (!find && profile.unit !== '') units.push({ label: profile.unit, value: profile.unit });
        }
      }
      this.selectOptions = units;
      if (!this.editMode) this.filterSelect = this.selectOptions[0] ? this.selectOptions[0].value : '';
      else this.filterSelect = this.sum.unit;
    },
    closeModal(param) {
      this.$emit('hide');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-modal >>> .modal-content
  display flex
  flex-direction column
  padding-bottom $space-3
  width 750px
  height 900px
  .modal__header
    display flex
    align-items center
    margin $space-3 0
    padding 0 $space-3
    .modal-title
      margin 0
    .close-button
      margin-left auto
      cursor pointer
  .modal__footer
    display flex
    margin $space-3 0
    padding 0 $space-3
  .modal__content
    flex 1
    padding $space-3
    .device-select
      padding-top $space-2
      &--header
        display flex
        flex-direction row
        &__filter
          margin-left auto
      &--name
        font-size $fs-h2
    .sum-profile
      display flex
      flex-direction row
      justify-content space-between
      padding $space-2
      font-size $fs-h3

  .buttons
    margin-left auto

.profile-name
  padding-left $space-3

.text-field
  display flex
  flex-direction column
  &--read-only
    .text-field__input
      background-color $light
      pointer-events none
  .text-field__label
    margin-bottom $space-1
    font-size $fs-h1
    &--hint
      margin-bottom 0px
  .text-field__required
    color $negative
    font-size $fs-h1
  .text-field__hint
    margin-bottom $space-1
    color $infos
    font-size $fs-body
  .text-field__input
    margin-bottom 'calc(%s + %s)' % ($space-little 15px)
    font-size $fs-h2
    &--error
      margin-bottom $space-little
  .text-field__error-message
    color $negative

</style>