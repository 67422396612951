<template>
  <div class="global-viewer">
    <NavInfo class="nav-alert">
      <template slot="navigation">
        <NavItem :selected="isHomeFullscreen"
                 class="t_navigation-item--screen"
                 @click="toggleFullscreen">{{$t('pages.data.navInfo.fullscreen')}}</NavItem>
        <NavItem v-if="user && user.featuresRights.graph.READ"
                 class="t_navigation-item--graph"
                 :selected="visualisationMode === 'graph'"
                 @click="setGraphMode">{{$t('pages.data.navInfo.graph')}}</NavItem>
      </template>
      <template slot="info">
        <InfoItemContainer>
          <SelectionInfo />
        </InfoItemContainer>
      </template>
    </NavInfo>
    <BuildingsList class="building-list" />
  </div>
</template>

<script>
import NavInfo from '@/app/components/layout/nav-info';
import NavItem from '@/app/components/layout/nav-info/components/navigation-item.vue';
import InfoItemContainer from '@/app/components/layout/nav-info/components/info-item-container.vue';
import BuildingsList from '@/app/components/buildings/buildings-list.vue';
import SelectionInfo from '@/app/pages/data/components/selection-info.vue';

export default {
  name: 'GlobalViewer',
  components: {
    BuildingsList,
    NavInfo,
    NavItem,
    InfoItemContainer,
    SelectionInfo,
  },
  computed: {
    isHomeFullscreen() {
      return this.$store.state.ui.home.fullscreen;
    },
    visualisationMode() {
      return this.$store.state.ui.visualisationMode;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    toggleFullscreen() {
      this.$store.dispatch('ui/toggleHomeFullscreen');
    },
    setGraphMode() {
      this.$store.commit('ui/SET_VISUALISATION_MODE', 'graph');
      this.$store.commit('oapp/setCurrentOapp', undefined);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.global-viewer
  padding 0.1px
  width 100%
  height 100%
  .nav-alert
    margin $space-2
</style>