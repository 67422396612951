<template>
  <div class="address-group">
    <div class="label">Address</div>
    <div class="field-container">
      <template v-for="(addressField, addressFieldName) in addressFields">
        <TextField :key="addressFieldName"
                   v-model="formValue[addressFieldName]"
                   :label="fieldsInfo[addressFieldName].label"
                   :hint="fieldsInfo[addressFieldName].hint"
                   :required="addressField.flags.presence === 'required'"
                   :errorMessage="errors[addressFieldName]" />
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import TextField from '@/app/components/inputs/text-field.vue';

export default {
  name: 'AddressForm',
  components: {
    TextField,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValue: {},
      errors: {},
      countryAlpha2: '',
      zipCode: '',
    };
  },
  computed: {
    topology() {
      return this.$store.state.topology.root;
    },
    graphSelectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    addressFields() {
      return _.omit(this.fields.children, ['latitude', 'longitude', 'city', 'countryAlpha2', 'zipCode']);
    },
    fieldsInfo() {
      return {
        line1: {
          label: 'Line 1',
          hint: 'Street address',
        },
        line2: {
          label: 'Line 2',
          hint: 'Additional',
        },
      };
    },
  },
  watch: {
    formValue: {
      handler(value) {
        value.city = this.edit ? this.selectedNode.address.city : this.selectedNode.name;
        value.countryAlpha2 = this.edit ? this.selectedNode.address.countryAlpha2 : this.countryAlpha2;
        value.zipCode = this.edit ? this.selectedNode.address.zipCode : this.selectedNode.zipCode;
        this.$emit('input', value);
      },
      deep: true,
    },
  },
  created() {
    this.resetNewNode();
    this.resetErrors();
    if (!this.edit) this.countryAlpha2 = this.getCountryAlpha2();
    else {
      this.countryAlpha2 = this.selectedNode.address.countryAlpha2;
      this.formValue.line1 = this.selectedNode.address.line1;
      this.formValue.line2 = this.selectedNode.address.line2;
    }
  },
  methods: {
    formHasError() {
      let hasErrors = false;
      this.resetErrors();
      Object.keys(this.addressFields).forEach(k => {
        if (this.addressFields[k].flags.presence !== 'required') return;
        switch (k) {
          case 'line1':
          case 'line2':
            if (this.formValue[k] === '') {
              this.errors[k] = `${k} can't be empty`;
              hasErrors = true;
            }
            break;
          default:
            break;
        }
      });
      return hasErrors;
    },
    resetNewNode() {
      if (!this.addressFields) return;
      Object.keys(this.addressFields).forEach(k => {
        this.$set(this.formValue, k, '');
      });
    },
    resetErrors() {
      if (!this.addressFields) return;
      Object.keys(this.addressFields).forEach(k => {
        this.$set(this.errors, k, '');
      });
    },
    getCountryAlpha2() {
      const country = this.topology.next.find(country => country.next && country.next.find(city => city.uuid === this.selectedNode.uuid));
      return country.alpha2;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.address-group
  .field-container
    padding $space-2
    border-radius $border-radius-smooth
    background-color $dash-light
</style>