<template>
  <div class="tag-filter">
    <div class="tag-filter__header">
      <h4 class="header-title">
        {{$t('components.propertiesSelection.select')}}
      </h4>
      <p class="header-subtitle">
        {{$t('components.propertiesSelection.subText')}}
      </p>
    </div>
    <div class="tag-list">
      <div v-for="p in properties"
           :key="p"
           class="tag-list__item-container">
        <Chip :value="$t(`components.propertiesSelection.${p}`)"
              :enabled="propIsSelected(p)"
              large
              @click.native="togglePropSelect(p)" />
      </div>
    </div>
  </div>
</template>

<script>
import Chip from '@/app/components/ui/chip.vue';

export default {
  name: 'PropSelection',
  components: {
    Chip,
  },
  props: {
    properties: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedFilters() {
      return this.$store.state.selections.filters;
    },
  },
  methods: {
    togglePropSelect(prop) {
      if (this.propIsSelected(prop)) this.$store.commit('selections/removeFilter', { key: prop });
      else this.$store.commit('selections/addFilter', { key: prop });
    },
    propIsSelected(prop) {
      return prop in this.selectedFilters;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.tag-filter
  padding 16px
  .tag-filter__header
    margin-bottom 16px
    width 110px
    .header-title
      margin 0
      text-transform uppercase
      font-weight 700
      font-size $fs-body
      line-height 1
    .header-subtitle
      font-size $fs-body
  .tag-list
    display flex
    flex-direction column
    .tag-list__item-container
      margin-bottom 4px
      width 100%
      &:last-child
        margin-bottom 0
</style>
