<template>
  <div class="sidebar-data">
    <div v-if="fetching"
         class="fetching-loader">
      <q-spinner-dots color="black"
                      :size="80" />
      <span class="fetching-text">{{$t('pages.graph.sidebarData.fetching')}}...</span>
    </div>
    <template v-else>
      <div class="header">
        <PeriodSelector v-model="intervalTime"
                        :showDateTime="true"
                        @input="updateDuration" />
        <span class="select-label">{{$t('pages.graph.sidebarData.profile')}} :</span>
        <SelectField v-model="selectedProfil"
                     :options="profils"
                     color="white" />
      </div>
      <div v-if="currentProfilData.length === 0"
           class="no-data">
        {{$t('pages.graph.sidebarData.noData')}}
      </div>
      <template v-else>
        <div v-if="currentProfilUnit !== ''"
             class="graph">
          <div class="graph-info">
            {{`${displayDuration.from} — ${displayDuration.to}`}}
          </div>
          <GraphDevice :data="graphData"
                       :unit="currentProfilUnit" />
        </div>
        <div class="data-table">
          <q-table :data="tableData"
                   :columns="tableColumns"
                   :hideBottom="true"
                   :pagination.sync="pagination" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';

import UtilsMixin from '@/app/mixins/utils';

import SelectField from '@/app/components/inputs/select-field.vue';
import PeriodSelector from '@/oapps/widgets/period-selector.vue';
import GraphDevice from '@/app/components/charts/graph-device.vue';

export default {
  name: 'SidebarData',
  components: {
    SelectField,
    PeriodSelector,
    GraphDevice,
  },
  mixins: [UtilsMixin],
  props: {
    node: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fetching: false,
      profils: [],
      selectedProfil: '0',
      profilsData: null,
      intervalTime: '24hours',
      duration: { number: 24, string: 'hours' },
      displayFormat: 'D MMM YYYY, HH[h]mm',
    };
  },
  computed: {
    currentProfilData() {
      if (!this.profilsData) return [];
      else return this.profilsData[this.selectedProfil].result;
    },
    graphData() {
      if (!this.profilsData) return { labels: [], values: [] };
      return this.currentProfilData.reduce(
        (acc, d) => {
          acc.labels.push(moment(d.time).format(this.displayFormat));
          acc.values.push(d.mean);
          return acc;
        },
        { labels: [], values: [] }
      );
    },
    currentProfilName() {
      return this.profils[this.selectedProfil].label;
    },
    currentProfilUnit() {
      return this.profils[this.selectedProfil].unit;
    },
    displayDuration() {
      if (this.duration.from && this.duration.to) {
        return {
          from: moment(this.duration.from).format(this.displayFormat),
          to: moment(this.duration.to).format(this.displayFormat),
        };
      }
      const duration = this.intervalToMoment(this.duration);
      return {
        from: moment(duration.from).format(this.displayFormat),
        to: moment(duration.to).format(this.displayFormat),
      };
    },
    tableColumns() {
      return [
        {
          name: 'date',
          field: 'date',
          required: true,
          label: 'Date',
          align: 'left',
        },
        {
          name: 'profile',
          field: 'value',
          required: true,
          label: `${this.currentProfilName} (${this.currentProfilUnit})`,
          align: 'right',
        },
      ];
    },
    tableData() {
      return this.currentProfilData.map(d => ({
        date: moment(d.time).format(this.displayFormat),
        value: this.formatData(d.mean),
      }));
    },
    pagination() {
      return {
        rowsPerPage: this.currentProfilData.length,
      };
    },
  },
  watch: {
    node() {
      this.fetchDeviceData();
      this.selectedProfil = '0';
    },
  },
  created() {
    this.fetchDeviceData();
  },
  methods: {
    async fetchDeviceData() {
      this.fetching = true;
      try {
        const duration = this.intervalToMoment(this.duration);
        const { data: profilsData } = await api.devices.data.read({
          deviceUuid: this.node.uuid,
          from: duration.from,
          to: duration.to,
        });
        this.profils = Object.keys(profilsData).map(k => ({
          label: this.$t(`pages.graph.sidebarData.profiles["${profilsData[k].name.toLowerCase()}"]`),
          unit: profilsData[k].unit,
          value: k,
        }));
        this.profilsData = profilsData;
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Error in fetching device data',
          type: 'negative',
          position: 'bottom-left',
        });
      }
      this.fetching = false;
    },
    async fetchDeviceDataTimePicker() {
      try {
        const { data: profilsData } = await api.devices.data.read({
          deviceUuid: this.node.uuid,
          from: this.duration.from,
          to: this.duration.to,
        });
        this.profils = Object.keys(profilsData).map(k => ({
          label: this.$t(`pages.graph.sidebarData.profiles["${profilsData[k].name.toLowerCase()}"]`),
          unit: profilsData[k].unit,
          value: k,
        }));
        this.profilsData = profilsData;
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Error in fetching device data',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    updateDuration(newDuration) {
      if (newDuration.value && newDuration.values) {
        this.intervalTime = newDuration.value;
        this.duration = newDuration.values;
        this.fetchDeviceData();
      }
      else {
        this.duration = { from: newDuration.from, to: newDuration.to};
        this.fetchDeviceDataTimePicker();
      }
    },
    intervalToMoment(duration) {
      const to = moment();
      const from = moment().subtract(duration.number, duration.string);
      return { from: from.format(), to: to.format() };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.sidebar-data
  overflow-y auto
  height 100%
  background-color white
  .fetching-loader
    display flex
    flex-direction column
    justify-content center
    align-items center
    height 100%
    .fetching-text
      letter-spacing 2px
      font-weight 700
      font-size $fs-h1
  .header
    display flex
    align-items center
    padding $space-2
    background-color $dash-light
    .select-label
      font-size $fs-h2
    .select-field
      margin-left $space-1
      width 250px
    .period-selector
      margin-left auto
      width fit-content
      margin 0 100px
  .no-data
    padding $space-3
    text-align center
    letter-spacing 2px
    font-weight 700
    font-size $fs-h1
  .graph
    padding $space-2
    .graph-info
      display flex
      justify-content center
      padding $space-1
      font-weight 300
      font-size $fs-h3
  .data-table
    margin-bottom 46px
    padding $space-2
    padding-top 0
</style>