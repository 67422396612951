<template>
  <SidebarExtensible v-model="sidebarOutState"
                     class="sidebar-out">
    <q-tabs v-model="currentTab"
            color="white"
            textColor="black">
      <template v-if="visualisationMode === '2d'">
        <q-tab slot="title"
               default
               name="oapps">{{$t('pages.data.sidebarOut.tabs.oapps')}}</q-tab>
      </template>
      <template v-else>
        <q-tab v-if="showNodeTab"
               slot="title"
               name="node">{{$t('pages.data.sidebarOut.tabs.node')}}</q-tab>
        <q-tab v-if="showCalcTab"
               slot="title"
               name="calc">{{$t('pages.data.sidebarOut.tabs.calc')}}</q-tab>
        <q-tab v-if="showDataTab"
               slot="title"
               name="data">{{$t('pages.data.sidebarOut.tabs.data')}}</q-tab>
      </template>
      <q-tab-pane class="oapps-tab"
                  name="oapps">
        <SidebarOapps />
      </q-tab-pane>
      <q-tab-pane v-if="showNodeTab"
                  name="node">
        <SidebarNode />
      </q-tab-pane>
      <q-tab-pane v-if="showCalcTab"
                  name="calc">
        <SidebarCalc />
      </q-tab-pane>
      <q-tab-pane v-if="showDataTab"
                  name="data">
        <SidebarData :node="graphSelectedNode" />
      </q-tab-pane>
    </q-tabs>
  </SidebarExtensible>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SidebarExtensible from '@/app/components/layout/sidebar/sidebar-extensible.vue';
import SidebarOapps from '@/app/pages/data/components/sidebar-oapps.vue';
import SidebarNode from '@/app/pages/data/components/sidebar-node.vue';
import SidebarCalc from '@/app/pages/data/components/sidebar-calc.vue';
import SidebarData from '@/app/pages/data/components/sidebar-data.vue';

export default {
  name: 'SidebarOut',
  components: {
    SidebarExtensible,
    SidebarOapps,
    SidebarNode,
    SidebarCalc,
    SidebarData,
  },
  data() {
    return {
      currentTab: '',
    };
  },
  computed: {
    ...mapState('ui', {
      sidebarOutState: state => state.home.sidebarOut,
      visualisationMode: state => state.visualisationMode,
    }),
    graphSelectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    nodeIsSelected() {
      return this.graphSelectedNode !== null;
    },
    nodeIsDevice() {
      return this.graphSelectedNode.nodeType === 'device';
    },
    user() {
      return this.$store.state.user.user;
    },
    showNodeTab() {
      return this.nodeIsSelected;
    },
    showCalcTab() {
      return this.nodeIsSelected && !this.nodeIsDevice && this.user.featuresRights.sum.READ;
    },
    showDataTab() {
      return this.nodeIsSelected && this.nodeIsDevice;
    },
  },
  watch: {
    graphSelectedNode(node) {
      if (!node) return;
      if (node.nodeType === 'device' && this.currentTab === 'calc') this.currentTab = 'data';
      if (node.nodeType !== 'device' && this.currentTab === 'data') this.currentTab = 'calc';
    },
    visualisationMode(mode) {
      if (mode === '2d') this.currentTab = 'oapps';
      else this.currentTab = 'node';
    },
  },
  methods: {
    toggleOappSidebardExpanded() {
      this.$store.commit('oapp/toggleSidebarExpanded');
    },
    ...mapMutations('ui', {
      setSidebarInState: 'SET_SIDEBARIN_STATE',
      setSidebarOutState: 'SET_SIDEBAROUT_STATE',
    }),
    toggleSidebar() {
      this.setSidebarOutState('large');
      this.setSidebarInState(true);
      this.sidebarExpanded = !this.sidebarExpanded;
      this.toggleOappSidebardExpanded();
    },
    closeSidebar() {
      this.setSidebarOutState('small');
      this.sidebarExpanded = false;
      this.toggleOappSidebardExpanded();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.q-tabs, .q-tabs >>> .q-tabs-panes
  height 100%

.q-tab-pane
  padding 0
  height 100%

.oapps-tab
  overflow-y auto
</style>
