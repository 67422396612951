<template>
  <div class="sum-item">
    <SumCreation :sumModal="sumModal"
                 :editMode="true"
                 :sum="sum"
                 @hide="closeModal" />
    <div class="sum-item__header">
      <div class="sum-info">
        <span class="sum-name"
              @click="toggleSumItem">
          {{ sum.name }}
        </span>
        <div v-if="collapsed && user.featuresRights.sum.WRITE"
             v-on-clickaway="onClickAway"
             class='sum-actions'>
          <span class="sum-menu__title"
                @click.self="toggleShowMenu">
            <q-icon name="mdi-settings-outline"
                    size="20px"
                    @click.native.self="toggleShowMenu" />
            <div v-if="showMenu"
                 class="sum-menu-list o-button o-button--shadows">
              <q-list :insetSeparator="true">
                <q-item @click.native="openModal">{{$t('pages.graph.sidebarSum.edit')}}</q-item>
                <q-item class="item-delete"
                        @click.native="deleteSum">{{$t('pages.graph.sidebarSum.delete')}}</q-item>
              </q-list>
            </div>
          </span>
        </div>

        <q-icon v-else-if="!collapsed"
                name="close"
                class="sum-close"
                size="30px"
                @click.native="toggleSumItem" />
      </div>
    </div>
    <transition name="fade">
      <div v-show="!collapsed"
           class="collapse-transition sum-item__body">

        <div class="header">
          <PeriodSelector v-model="intervalTime"
                          :showDateTime="true"
                          @input="updateDuration" />
        </div>
        <div class="total">
          <span> {{ `Total: ${ total}` }} </span>
        </div>
        <div class="graph">
          <div class="graph-info">
            {{`${displayDuration.from} — ${displayDuration.to}`}}
          </div>
          <GraphDevice :data="graphData"
                       :unit="currentSum && currentSum.unit" />
        </div>
        <div class="data-table">
          <q-table :data="tableData"
                   :columns="tableColumns"
                   :hideBottom="true"
                   :pagination.sync="pagination" />
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import { mixin as clickaway } from 'vue-clickaway';

import UtilsMixin from '@/app/mixins/utils';
import PeriodSelector from '@/oapps/widgets/period-selector.vue';
import GraphDevice from '@/app/components/charts/graph-device.vue';
import SumCreation from '@/app/pages/data/components/sum-creation.vue';

export default {
  name: 'SumItem',
  components: {
    PeriodSelector,
    GraphDevice,
    SumCreation,
  },
  mixins: [clickaway, UtilsMixin],
  props: {
    sum: {
      type: Object,
      default: () => {},
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    sumdExpanded: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fetching: false,
      sumData: [],
      intervalTime: '24hours',
      duration: { number: 24, string: 'hours' },
      displayFormat: 'D MMM YYYY, HH[h]mm',
      showMenu: false,
      sumModal: false,
      total: 0,
    };
  },
  computed: {
    currentSum() {
      return this.$store.getters['sum/currentSum'];
    },
    graphData() {
      if (!this.sumData) return { labels: [], values: [] };
      return this.sumData.reduce(
        (acc, d) => {
          acc.labels.push(moment(d.time).format(this.displayFormat));
          acc.values.push(d.mean);
          return acc;
        },
        { labels: [], values: [] }
      );
    },
    tableData() {
      return this.sumData.map(d => ({
        date: moment(d.time).format(this.displayFormat),
        value: this.formatData(d.mean),
      }));
    },
    displayDuration() {
      if (this.duration.from && this.duration.to) {
        return {
          from: moment(this.duration.from).format(this.displayFormat),
          to: moment(this.duration.to).format(this.displayFormat),
        };
      }
      const duration = this.intervalToMoment(this.duration);
      return {
        from: moment(duration.from).format(this.displayFormat),
        to: moment(duration.to).format(this.displayFormat),
      };
    },
    tableColumns() {
      return [
        {
          name: 'date',
          field: 'date',
          required: true,
          label: 'Date',
          align: 'left',
          sortable: true,
        },
        {
          name: 'profile',
          field: 'value',
          required: true,
          label: `${this.$t('pages.graph.sidebarSum.value')} (${this.currentSum && this.currentSum.unit})`,
          align: 'right',
          sortable: true,
        },
      ];
    },
    pagination() {
      return {
        rowsPerPage: this.sumData.length,
      };
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    currentSum() {
      if (!this.currentSum) {
        this.collapsed = true;
      } else if (this.currentSum.id === this.sum.id) this.fetchSum();
    },
  },
  methods: {
    setCurrentSumId(sumId) {
      this.$store.commit('sum/setCurrentSumId', sumId);
    },
    closeModal() {
      this.sumModal = false;
    },
    openModal() {
      this.sumModal = true;
      this.showMenu = false;
    },
    async deleteSum() {
      try {
        await this.$q.dialog({
          title: this.$t('pages.graph.sidebarSum.deleteSum.dialogTitle'),
          ok: true,
          cancel: true,
          color: 'black',
        });
        try {
          await this.$store.dispatch('sum/deleteSum', this.sum.id);
          this.$q.notify({ message: this.$t('pages.graph.sidebarSum.deleteSum.success'), type: 'positive', position: 'bottom-left' });
        } catch (error) {
          this.$q.notify({ message: this.$t('pages.graph.sidebarSum.deleteSum.error'), type: 'negative', position: 'bottom-left' });
        }
      } catch (error) {
        // when click on dialog cancel button
      }
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
    onClickAway() {
      this.showMenu = false;
    },
    async fetchSum() {
      this.fetching = true;
      const duration = this.intervalToMoment(this.duration);
      const { data } = await api.sums.data.read(this.currentSum.id, duration.from, duration.to);
      this.fetching = false;
      this.sumData = data.res;
      this.total = data.total;
    },
    async fetchSumTimePicker() {
      try{
        this.fetching = true;
        const { data } = await api.sums.data.read(this.currentSum.id, this.duration.from, this.duration.to);
        this.fetching = false;
        this.sumData = data.res;
        this.total = data.total;
      } catch (error) {
        this.$q.notify({ message: `An error occured when fetching data`, type: 'negative', position: 'bottom-left' });
      }
    },
    toggleSumItem() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) this.setCurrentSumId('');
      else {
        this.setCurrentSumId(this.sum.id);
        this.showMenu = false;
      }
    },
    emitCloseClick() {
      this.collapsed = true;
      this.setCurrentSumId('');
      this.cleanCanvas();
    },
    intervalToMoment(duration) {
      const to = moment();
      const from = moment().subtract(duration.number, duration.string);
      return { from: from.format(), to: to.format() };
    },
    updateDuration(newDuration) {
      if (newDuration.value && newDuration.values) {
        this.intervalTime = newDuration.value;
        this.duration = newDuration.values;
        this.fetchSum();
      }
      else {
        this.duration = { from: newDuration.from, to: newDuration.to};
        this.fetchSumTimePicker();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.sum-item
  display flex
  flex-direction column
  background-color white
  .sum-item__header
    display flex
    justify-content flex-start
    align-items center
    width 100%
    border-bottom $border-light solid $silver
    cursor pointer
    .sum-info
      display flex
      justify-content space-between
      align-items center
      width 100%
      height 61px
      user-select none
      .sum-name
        flex 2
        padding-left 16px
        height 100%
        text-transform uppercase
        font-weight 700
        font-size $fs-h2
        line-height 61px
      .sum-actions
        .sum-menu__title
          position relative
          margin-right $space-1
          font-size $fs-h2
          .sum-menu-list
            position absolute
            top 100%
            right $space-1
            z-index 10
            padding 0
            width 130px
            border-radius $border-radius-smooth
            background-color white
            .q-list
              padding 0
              border unset
              .q-item
                font-weight 400
                &:hover
                  background-color $grey
                &:after
                  left 0 // make the separator line full width
              .item-delete
                color $negative
      .sum-close
        margin-right 10px
        cursor pointer
  .sum-item__main, .sum-item__body
    .header
      display flex
      align-items center
      padding $space-2
      background-color $dash-light
      .select-label
        font-size $fs-h2
      .o-select
        margin-left $space-1
        width 250px
      .period-selector
        margin-left auto
        width fit-content
        margin 0 100px
    .total
      padding $space-2
      background-color $dash-light
      font-size $fs-h2
    .graph
      padding $space-2
      .graph-info
        display flex
        justify-content center
        padding $space-1
        font-weight 300
        font-size $fs-h3
    .data-table
      margin-bottom 46px
      padding $space-2
      padding-top 0
  .sum-item__footer
    display flex
    justify-content center
    padding 16px
  .sum__slot
    min-height 400px
    background-color #f7f7f5

// VUE.js transition classes
.collapse-transition
  overflow hidden
  transition max-height 0.3s ease 0.2s

.fade-enter-active, .fade-leave-active
  max-height 4000px

.fade-enter, .fade-leave-to
  max-height 0px
  transition max-height 0.3s cubic-bezier(0, 1, 0, 1) 0s
  @media screen and (max-width: 500px)
    transition none
</style>
