<template>
  <q-modal v-model="show"
           class="create-node-modal"
           @hide="closeModal"
           @show="onOpenModal">
    <div class="modal__header">
      <h4 class="modal-title">{{`${edit ? 'Edit' : 'Create'} a ${selectedChildType || 'node'}`}}</h4>
      <q-icon name="mdi-close"
              class="close-button"
              size="45px"
              @click.native="closeModal" />
    </div>
    <div class="modal__content">
      <template v-if="!edit">
        <label class="label">Choose a node type</label>
        <template v-if="childNodes.length <= 4">
          <div class="node-list">
            <button v-for="(child, idx) in childNodes"
                    :key="idx"
                    :class="nodesButtonClass(child.value, selectedChildType)"
                    class="o-button o-button--hover o-button--bordered"
                    @click="selectedChildType = child.value">{{child.label}}</button>
          </div>
        </template>
        <SelectField v-else
                     v-model="selectedChildType"
                     :options="childNodes"
                     color="white" />
      </template>
      <form v-if="nodeFields"
            class="form"
            @submit.prevent="edit ? updateNode() : createNode()">
        <template v-for="(field, fieldName) in nodeFields">
          <!-- country -->
          <CountrySelect v-if="fieldName === 'alpha2'"
                         :key="fieldName"
                         v-model="inputCountryValue"
                         :required="field.flags.presence === 'required'"
                         :errorMessage="errors[fieldName]"
                         @selected="code => newNode.alpha2 = code" />
          <!-- address -->
          <AddressForm v-else-if="fieldName === 'address'"
                       ref="addressForm"
                       :key="fieldName"
                       :fields="field"
                       :edit="edit"
                       @input="a => newNode.address = a" />
          <!-- premise type -->
          <div v-else-if="fieldName === 'type'"
               :key="fieldName"
               :class="premiseTypeContainerClass(errors[fieldName] !== '')"
               class="premise-type-container">
            <div class="label">Premise type</div>
            <div class="premise-type">
              <q-radio v-for="child in field.valids"
                       :key="child"
                       v-model="newNode[fieldName]"
                       class="premise-type-radio"
                       color="black"
                       :val="child"
                       :label="child" />
            </div>
            <span v-if="errors[fieldName]"
                  class="premise-type__error-message">{{errors[fieldName]}}</span>
          </div>
          <!-- plug type -->
          <div v-else-if="fieldName === 'virtual'"
               :key="fieldName"
               class="plug-type">
            <div class="label">Is the plug Virtual?</div>
            <q-checkbox v-model="newNode[fieldName]"
                        class="plug-type__checkbox"
                        label="plug is virtual"
                        color="black" />
          </div>
          <!-- others -->
          <TextField v-else
                     :key="fieldName"
                     v-model="newNode[fieldName]"
                     :label="fieldsInfo[fieldName].label"
                     :hint="fieldsInfo[fieldName].hint"
                     :type="field.type === 'number' ? 'number' : 'text'"
                     :required="field.flags && field.flags.presence === 'required'"
                     :errorMessage="errors[fieldName]" />
        </template>
        <button v-if="selectedChildType"
                class="create-button o-button o-button--shadows o-button--hover o-button--black"
                type="submit">{{edit ? 'UPDATE' :'CREATE'}}</button>
      </form>
    </div>
  </q-modal>
</template>

<script>
import api from 'api';
import _ from 'lodash';

import TextField from '@/app/components/inputs/text-field.vue';
import SelectField from '@/app/components/inputs/select-field.vue';
import CountrySelect from '@/app/pages/data/components/country-select.vue';
import AddressForm from '@/app/pages/data/components/address-form.vue';

export default {
  name: 'CreateNodeModal',
  components: {
    TextField,
    SelectField,
    CountrySelect,
    AddressForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    node: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedChildType: null,
      childNodes: [],
      nodeSchema: null,
      inputCountryValue: '',
      newNode: {},
      errors: {},
    };
  },
  computed: {
    countries() {
      return this.$store.state.countries.collection;
    },
    nodeFields() {
      if (!this.nodeSchema) return null;
      let nodeFields = _.omit(this.nodeSchema.children, [
        '_id',
        '_key',
        '_rev',
        'nodeType',
        'uuid',
        'metadata',
        'alpha3',
        'idIFC',
        'properties',
      ]);
      if (this.selectedChildType === 'country') nodeFields = _.omit(nodeFields, ['name']);
      return nodeFields;
    },
    fieldsInfo() {
      return {
        name: {
          label: 'Name',
          hint: `name the ${this.selectedChildType}`,
        },
        country: {
          label: 'Country',
          hint: 'search the country you want to add',
        },
        description: {
          label: 'Description',
          hint: `description for the ${this.selectedChildType}`,
        },
        zipCode: {
          label: 'Zip code',
          hint: `Zip code of the ${this.selectedChildType}`,
        },
        level: {
          label: 'Level',
          hint: `Level number of the ${this.selectedChildType}`,
        },
        x: {
          label: 'X Coordinate',
          hint: `X Coordinate value of the ${this.selectedChildType}`,
        },
        y: {
          label: 'Y Coordinate',
          hint: `Y Coordinate value of the ${this.selectedChildType}`,
        },
        z: {
          label: 'Z Coordinate',
          hint: `Z Coordinate value of the ${this.selectedChildType}`,
        },
      };
    },
  },
  watch: {
    async selectedChildType(selectedType) {
      if (!selectedType) return;
      const { data: nodeSchema } = await api.node.global.getNodeSchema(selectedType);
      this.nodeSchema = nodeSchema;
    },
    nodeFields() {
      this.resetNewNode();
      this.resetErrors();
      if (this.edit) this.setNodeValues();
    },
  },
  created() {
    if (this.countries.length === 0) this.$store.dispatch('countries/fetch');
  },
  methods: {
    async createNode() {
      if (this.formHasErrors()) return;
      this.newNode.parentUuid = this.node.uuid;
      this.newNode.nodeType = this.selectedChildType;
      try {
        await this.$store.dispatch('topology/create', { node: this.newNode, nodeParent: this.node });
        this.$q.notify({ message: `${this.selectedChildType} successfully created`, type: 'positive', position: 'bottom-left' });
        this.closeModal();
      } catch (error) {
        this.$q.notify({ message: `Error on ${this.selectedChildType} creation`, type: 'negative', position: 'bottom-left' });
      }
    },
    async updateNode() {
      if (this.formHasErrors()) return;
      try {
        await this.$store.dispatch('topology/update', { updatedData: this.newNode, node: this.node });
        this.$q.notify({ message: `${this.selectedChildType} successfully updated`, type: 'positive', position: 'bottom-left' });
        this.closeModal();
      } catch (error) {
        this.$q.notify({ message: `Error on ${this.selectedChildType} creation`, type: 'negative', position: 'bottom-left' });
      }
    },
    formHasErrors() {
      let hasErrors = false;
      this.resetErrors();
      Object.keys(this.newNode).forEach(k => {
        if (this.nodeFields[k].flags.presence !== 'required') return;
        switch (k) {
          case 'name':
          case 'description':
          case 'zipCode':
          case 'level':
          case 'type':
            if (this.newNode[k] === '') {
              this.errors[k] = `${k} can't be empty`;
              hasErrors = true;
            }
            break;
          case 'alpha2':
            if (this.inputCountryValue === '') {
              this.errors[k] = `country can't be empty`;
              hasErrors = true;
            } else if (this.newNode[k] === '' || !this.countryExists(this.newNode[k])) {
              this.errors[k] = `country doesn't exist`;
              hasErrors = true;
            }
            break;
          case 'address':
            if (this.$refs.addressForm[0].formHasError()) hasErrors = true;
            break;
          default:
            break;
        }
      });
      return hasErrors;
    },
    nodesButtonClass(currentValue, selectedValue) {
      return {
        'o-button--black': selectedValue === currentValue,
      };
    },
    premiseTypeContainerClass(hasError) {
      return {
        'premise-type-container--error': hasError,
      };
    },
    async fetchChildTypes() {
      const { data: childs } = await api.node.global.getChildNodeTypes(this.node.nodeType);
      this.childNodes = childs.map(c => ({ label: c, value: c }));
    },
    countryExists(codeAlpha2) {
      return this.countries.find(c => c.alpha2 === codeAlpha2);
    },
    resetNewNode() {
      if (!this.nodeFields) return;
      Object.keys(this.nodeFields).forEach(k => {
        if (k === 'virtual') this.$set(this.newNode, k, false);
        else this.$set(this.newNode, k, '');
      });
    },
    resetErrors() {
      if (!this.nodeFields) return;
      Object.keys(this.nodeFields).forEach(k => {
        this.$set(this.errors, k, '');
      });
    },
    setNodeValues() {
      if (!this.nodeFields) return;
      Object.keys(this.nodeFields).forEach(k => {
        if (!this.node[k]) this.newNode[k] = '';
        else if (k === 'alpha2') {
          this.inputCountryValue = this.countries.find(c => c.alpha2 === this.node[k]).value;
          this.newNode[k] = this.node[k];
        } else {
          this.newNode[k] = this.node[k];
        }
      });
    },
    resetData() {
      // data has to be reset because the modal is never destroyed
      this.selectedChildType = null;
      this.childNodes = [];
      this.nodeSchema = null;
      this.inputCountryValue = '';
      this.errors = {};
      this.newNode = {};
    },
    closeModal() {
      this.resetData();
      this.$emit('hide');
    },
    onOpenModal() {
      this.resetNewNode();
      if (this.edit) {
        this.selectedChildType = this.node.nodeType;
      } else {
        // create
        this.fetchChildTypes();
      }
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.create-node-modal >>> .modal-content
  display flex
  flex-direction column
  overflow visible
  min-width 500px
  max-width @min-width
  .modal__header
    display flex
    align-items center
    padding $space-2 $space-3
    background-color black
    color white
    .modal-title
      margin 0
      line-height 1
    .close-button
      margin-left auto
      cursor pointer
  .modal__content
    flex 1
    overflow-y auto
    padding $space-3
    .text-field
      margin-bottom $space-1
    .label
      margin-bottom $space-1
      font-size $fs-h1
    .select-field
      margin-bottom $space-2
      font-size $fs-h2
    .create-button
      display block
      margin-top $space-2
      margin-left auto
    .node-list, .premise-type
      display flex
      justify-content center
      margin-bottom $space-1
      .o-button
        margin $space-1 $space-2
        background-color white
        text-transform uppercase
    .premise-type-container
      margin-bottom 'calc(%s + %s)' % ($space-1 23px)
      &--error
        margin-bottom $space-1 !important
        .premise-type-radio
          margin-bottom 'calc(%s + %s)' % ($space-2 $space-1)
    .premise-type
      flex-wrap wrap
      .premise-type-radio
        flex 1
        margin 0 $space-2
        font-size $fs-h2
    .premise-type__error-message
      color $negative
    .plug-type
      .plug-type__checkbox
        display block
        margin-bottom $space-2
        font-size $fs-h2
</style>