<template>
  <div class="sidebar-calc">
    <SumItem v-for="sum in sumsOfNode"
             :key="sum.id"
             :sum="sum"
             :collapsed="currentSumId !== sum.id"
             :class="'sum-item ' + sumClass(sum.id)"
             @oappCollapsed="closeItemEvent()" />
    <div  v-if="user.featuresRights.sum.WRITE"
        :class="showButton"
         @click="sumModal = true">
      {{$t('pages.graph.sidebarSum.createSum')}}
    </div>
    <SumCreation :sumModal="sumModal"
                 @hide="sumModal = false" />
  </div>
</template>

<script>
import SumItem from '@/app/pages/data/components/sum-item.vue';
import SumCreation from '@/app/pages/data/components/sum-creation.vue';

export default {
  name: 'SidebarCalc',
  components: {
    SumItem,
    SumCreation,
  },
  data() {
    return {
      sumModal: false,
    };
  },
  computed: {
    sums() { return this.$store.state.sum.collection; },
    sumInstallStatus() { return this.$store.state.sum.install; },
    currentSumId() { return this.$store.state.sum.currentSumId; },
    graphSelectedNode() {
      return this.$store.state.topology.selectedNode;
    },
    showButton() {
      return this.currentSumId !== '' ? 'add-button--hidden' : 'add-button';
    },
    sumsOfNode() {
      if (!this.graphSelectedNode) return [];
      const sums = this.sums.filter(sum => this.checkIfNodeIsUnderCurrentNode(this.graphSelectedNode, sum.nodeUuid));
      return sums;
    },
    user() {
      return this.$store.state.user.user;
    },

  },
  watch: {
    graphSelectedNode() {
      if (this.currentSumId) {
        const find = this.sumsOfNode.find(sum => sum.id === this.currentSumId);
        if (!find) {
          this.setCurrentSumId('');
        }
      }
    },
  },
  methods: {
    setCurrentSumId(sumId) {
      this.$store.commit('sum/setCurrentSumId', sumId);
    },
    sumClass(id) {
      return this.currentSumId && this.currentSumId !== id ? 'sum-item-hidden' : '';
    },
    checkIfNodeIsUnderCurrentNode(currentNode, nodeUuid) {
      if (currentNode.uuid === nodeUuid) return true;

      if (currentNode.next) {
        const nodeUuidIsUnderCurrentNode = currentNode.next.map(next => this.checkIfNodeIsUnderCurrentNode(next, nodeUuid));
        return nodeUuidIsUnderCurrentNode.some(node => node);
      }
      return false;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.sidebar-calc
  overflow-y auto
  padding-bottom $space-3
  height 100%
  background-color white
  .sum-item
    visibility visible
    max-height 4000px
    opacity 1
    transition max-height 0.5s cubic-bezier(1, 0, 1, 0) 0s, visibility 0s linear 0s, opacity 0.2s linear 0.5s
  .sum-item-hidden
    visibility hidden
    max-height 0px
    opacity 0
    transition max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s
  .add-button
    margin 20px
    padding 10px 15px
    border-width thin
    border-style solid
    border-color black
    border-radius 20px
    background-color white
    color $dark
    text-align center
    text-transform uppercase
    font-weight 700
    font-size $fs-h1
    cursor pointer
    transition opacity 1.5s ease-in
    &--hidden
      visibility hidden
      max-height 0px
      opacity 0
      transition max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s
</style>
