<template>
  <div class="info-item-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InfoItemContainer',
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.info-item-container
  padding $space-2
  border-right $border-light solid $light
</style>