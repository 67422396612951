<template>
  <q-modal v-model="show"
           class="add-device-modal"
           @hide="closeModal"
           @show="onOpenModal">
    <div class="modal__header">
      <h4 class="modal-title">Add a device</h4>
      <q-icon name="mdi-close"
              class="close-button"
              size="45px"
              @click.native="closeModal" />
    </div>
    <div class="modal__content">
      <form class="form-add-device"
            @submit.prevent="addDevice">
        <TextField v-model="fields.uuid.value"
                   :label="fields.uuid.label"
                   type="text"
                   :required="fields.uuid.required"
                   :errorMessage="fields.uuid.error" />
        <Field class="type-select"
               :label="fields.deviceType.label"
               :errorMessage="fields.deviceType.error"
               :required="fields.deviceType.required">
          <QSelect v-model="fields.deviceType.value"
                   class="o-select"
                   :class="{'o-select--negative': fields.deviceType.error}"
                   :options="deviceTypes" />
        </Field>
        <Field :label="fields.description.label"
               :required="fields.description.required"
               :errorMessage="fields.description.error">
          <textarea v-model="fields.description.value"
                    class="o-textarea"
                    :rows="3" />
          </Field>
        <button class="add-button o-button o-button--shadows o-button--hover o-button--black"
                type="submit">ADD</button>
      </form>
    </div>
  </q-modal>
</template>

<script>
import api from 'api';

import Field from '@/app/components/inputs/field.vue';
import TextField from '@/app/components/inputs/text-field.vue';

export default {
  name: 'AddDeviceModal',
  components: {
    TextField,
    Field,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    plug: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deviceTypes: [],
      fields: {
        uuid: {
          label: 'Uuid',
          value: '',
          error: '',
          required: true,
        },
        deviceType: {
          label: 'Type',
          value: null,
          error: '',
          required: true,
        },
        description: {
          label: 'Description',
          value: '',
          error: '',
          required: false,
        },
      },
    };
  },
  async created() {
    const { data: deviceTypes } = await api.devices.deviceTypes.fetch();
    this.deviceTypes = deviceTypes.map(t => ({ value: t.id, label: t.name }));
  },
  methods: {
    async addDevice() {
      if (!this.formIsValid()) return;
      try {
        const { data: device } = await api.devices.create({
          deviceTypeId: this.fields.deviceType.value,
          uuid: this.fields.uuid.value,
          plugUuid: this.plug.uuid,
          description: this.fields.description.value,
          x: this.plug.x,
          y: this.plug.y,
        });
        this.$set(this.plug, 'next', []);
        device.nodeType = 'device';
        this.plug.next.push(device);
        this.$q.notify({ message: `Device successfully added`, type: 'positive', position: 'bottom-left' });
        this.closeModal();
      } catch (error) {
        this.$q.notify({ message: `Error on device add`, type: 'negative', position: 'bottom-left' });
      }
    },
    formIsValid() {
      this.resetFormErrors();
      let formIsValid = true;
      Object.keys(this.fields).forEach(k => {
        if (this.fields[k].required && (this.fields[k].value === '' || !this.fields[k].value)) {
          this.fields[k].error = `${this.fields[k].label} can't be empty`;
          formIsValid = false;
        }
      });
      return formIsValid;
    },
    resetFormData() {
      Object.keys(this.fields).forEach(k => {
        this.fields[k].value = '';
      });
    },
    resetFormErrors() {
      Object.keys(this.fields).forEach(k => {
        this.fields[k].error = '';
      });
    },
    closeModal() {
      this.resetFormData();
      this.$emit('hide');
    },
    onOpenModal() {},
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.add-device-modal >>> .modal-content
  display flex
  flex-direction column
  overflow visible
  min-width 500px
  max-width @min-width
  .modal__header
    display flex
    align-items center
    padding $space-2 $space-3
    background-color black
    color white
    .modal-title
      margin 0
      line-height 1
    .close-button
      margin-left auto
      cursor pointer
  .modal__content
    flex 1
    overflow-y auto
    padding $space-3
    .form-add-device
      .add-button
        display block
        margin-left auto
    .text-field, .type-select
      margin-bottom $space-1
</style>