<template>
  <div>
    <q-icon v-if="getIcon.type === 'icon'"
            class="noselect"
            :class="nodeClass"
            :name="getIcon.name"
            :color="iconColor(node.nodeType)" />
    <component v-if="getIcon.type === 'svg'"
               :is="getIcon.name"
               size="60%" />
  </div>
</template>

<script>
export default {
  name: 'IconNodeType',
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    nodeClass: {
      type: String,
      default: 'nsm',
    },
  },
  data() {
    return {
      nodesIcon: {
        default: {
          type: 'icon',
          name: 'place',
        },
        root: {
          type: 'icon',
          name: 'public',
        },
        country: {
          type: 'icon',
          name: 'flag',
        },
        city: {
          type: 'icon',
          name: 'fas fa-city',
        },
        building: {
          type: 'icon',
          name: 'fas fa-building',
        },
        floor: {
          type: 'icon',
          name: 'mdi-buffer',
        },
        space: {
          type: 'icon',
          name: 'fas fa-square',
        },
        plug: {
          type: 'icon',
          name: 'mdi-power-plug',
        },
        //energy
        alarm: {
          type: 'icon',
          name: 'fas fa-bell',
        },
        water: {
          type: 'icon',
          name: 'fas fa-tint',
        },
        water_hot: {
          type: 'icon',
          name: 'fas fa-tint',
        },
        water_cold: {
          type: 'icon',
          name: 'fas fa-tint',
        },
        electricity: {
          type: 'icon',
          name: 'fas fa-bolt',
        },
        electricity_aux: {
          type: 'icon',
          name: 'mdi-fan',
        },
        electricity_heating: {
          type: 'icon',
          name: 'fas fa-thermometer-full',
        },
        electricity_ecs: {
          type: 'icon',
          name: 'fas fa-tint',
        },
        electricity_light: {
          type: 'icon',
          name: 'fas fa-lightbulb',
        },
        electricity_process: {
          type: 'icon',
          name: 'fas fa-plug',
        },
        electricity_other: {
          type: 'icon',
          name: 'fas fa-bolt',
        },
        states: {
          type: 'icon',
          name: 'fas fa-clipboard-list',
        },
        air: {
          type: 'icon',
          name: 'fas fa-wind',
        },
      },
      sensorsIcon: {
        default: {
          type: 'icon',
          name: 'mdi-adjust',
        },
      },
    };
  },
  computed: {
    isSensor() {
      return this.node && this.node.nodeType === 'device';
    },
    getIcon() {
      let iconType = null;
      if (this.isSensor) iconType = this.sensorsIcon[this.node.type] || this.sensorsIcon.default;
      else iconType = this.nodesIcon[this.node.nodeType] || this.nodesIcon.default;
      return iconType;
    },
  },
  methods: {
    iconColor(nodeType) {
      switch (nodeType) {
        case 'water_hot':
          return 'red';
        case 'water_cold':
          return 'blue';
        case 'electricity_ecs':
          return 'orange';
        default:
          return '#686de0';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
$xs_size ?= 12px
$sm_size ?= 20px
$md_size ?= 24px

.nxs
  font-size $xs_size !important

.nsm
  font-size $sm_size !important

.nmd
  font-size $md_size !important

.svg
  &.nxs
    min-width $xs_size
    min-height $xs_size
    width $xs_size
    height $xs_size
  &.nsm
    min-width $sm_size
    min-height $sm_size
    width $sm_size
    height $sm_size
  &.nmd
    min-width $md_size
    min-height $md_size
    width $md_size
    height $md_size
</style>