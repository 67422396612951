<template>
  <div>
    <OappItem v-for="oapp of sidebarOapps"
              :id="oapp.id"
              :key="oapp.id"
              :name="oapp.name"
              :displayedName="$t(`oapps.${oapp.name.toLowerCase()}.name`)"
              :icon="oapp.icon"
              :limits="limits"
              :value="isCurrentOapp(oapp.id) ? oappsValues[oapp.id] : {label: '', value: null}"
              :type="oapp.type"
              :collapsed="!isCurrentOapp(oapp.id)"
              :class="'oapp-item ' + oappClass(oapp.id)"
              :dashboardExpanded="sidebarExpanded"
              @dashboardClick="toggleSidebar"
              @oappCollapsed="closeItemEvent()">
      <component v-model="oappsValues[oapp.id]"
                 :is="oapp.component"
                 :oapp="oapp"
                 :timeRange="timeRange"
                 :global="isCurrentOapp(oapp.id) && currentOapp.data ? currentOapp.data.global : undefined"
                 :graphAVG="isCurrentOapp(oapp.id) && currentOapp.data ? currentOapp.data.graphAVG: undefined"
                 :liveMode="timeRangeIsLive"
                 :values="isCurrentOapp(oapp.id) && currentOapp.data ? currentOapp.data.values: undefined"
                 :limits="limits"
                 :selected="Object.keys(filteredSelection)"
                 :sidebarExpanded="isCurrentOapp(oapp.id) && sidebarExpanded" />
    </OappItem>
    <router-link :to="{ name:'store' }"
                 class="link--no-underline">
      <div v-if="user && user.featuresRights.store.READ"
           class="add-oapp-button o-button o-button--shadows o-button--hover o-button--squared">
        {{$t('pages.data.sidebarOut.others.getNewApps')}}
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import OappItem from '@/app/pages/data/components/oapp-item.vue';

export default {
  name: 'SidebarOapps',
  components: {
    OappItem,
  },
  data() {
    return {
      oappsValues: {},
    };
  },
  computed: {
    ...mapState('ui', {
      sidebarOutState: state => state.home.sidebarOut,
    }),
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    oappSidebarExpanded() {
      return this.$store.state.oapp.sidebarExpanded;
    },
    timeRangeIsLive() {
      return this.$store.state.selections.time.range === 'live';
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    sidebarOapps() {
      return this.$store.getters['oapp/installed'].filter(oapp => !oapp.hidden);
    },
    timeRange() {
      return {
        dateFrom: this.$store.state.selections.time.from,
        dateTo: this.$store.state.selections.time.to,
      };
    },
    limits() {
      if (!this.currentOapp || !this.currentOapp.data || !this.currentOapp.type) return { min: 0, max: 0 };
      return {
        min: this.currentOapp.limits[this.currentOapp.type].min,
        max: this.currentOapp.limits[this.currentOapp.type].max,
      };
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    isCurrentOapp(oappId) {
      return this.currentOapp && this.currentOapp.id === oappId;
    },
    toggleOappSidebardExpanded() {
      this.$store.commit('oapp/toggleSidebarExpanded');
    },
    ...mapMutations('ui', {
      setSidebarInState: 'SET_SIDEBARIN_STATE',
      setSidebarOutState: 'SET_SIDEBAROUT_STATE',
    }),
    toggleSidebar() {
      this.setSidebarOutState('large');
      this.setSidebarInState(true);
      this.sidebarExpanded = !this.sidebarExpanded;
      this.toggleOappSidebardExpanded();
    },
    closeSidebar() {
      this.setSidebarOutState('small');
      this.sidebarExpanded = false;
      this.toggleOappSidebardExpanded();
    },
    closeItemEvent() {
      this.oappId = '';
    },
    oappClass(id) {
      return this.currentOapp && !this.isCurrentOapp(id) ? 'oapp-item-hidden' : '';
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.sidebar-out
  .add-button
    margin 20px
    padding 10px 15px
    border-width thin
    border-style solid
    border-color black
    border-radius 20px
    background-color white
    color $dark
    text-align center
    text-transform uppercase
    font-weight 700
    font-size $fs-h1
    cursor pointer
    transition opacity 1.5s ease-in
  .add-oapp-button
    margin $space-2 auto
    width fit-content
    background-color white
    color black
    text-transform uppercase
</style>