<template>
  <div class="control-time">
    <div class="button-container">
      <div class="button-container__top">
        <button :class="buttonClass('year')"
                class="o-button o-button--year"
                @click="selectTimeRange('year')">
          {{$t('components.timeControle.year')}}
        </button>
        <button :class="buttonClass('month')"
                class="o-button o-button--month"
                @click="selectTimeRange('month')">
          {{$t('components.timeControle.month')}}
        </button>
        <button :class="buttonClass('week')"
                class="o-button o-button--week"
                @click="selectTimeRange('week')">
          {{$t('components.timeControle.week')}}
        </button>
        <button :class="buttonClass('day')"
                class="o-button o-button--day"
                @click="selectTimeRange('day')">
          {{$t('components.timeControle.day')}}
        </button>
      </div>
      <div class="button-container__bottom">
        <button :class="buttonClass('live')"
                class="o-button o-button--live"
                @click="selectTimeRange('live')">
          {{$t('components.timeControle.live')}}
        </button>
      </div>
    </div>
    <div class="date-input-container">
      <span :class="selectedClass('startDate')"
            class="control-time__date-input theme-dark"
            @click.stop.self="openDrawer('startDate')">
        {{ formatedDateTime.startDate }}
      </span>
      <span :class="selectedClass('startTime')"
            class="date-input__hours"
            @click.stop.self="openDrawer('startTime')">
        {{ formatedDateTime.startTime }}
      </span>
      <ChevronIcon class="date-input__chevron"
                   size="10px" />
      <span :class="selectedClass('endDate')"
            class="control-time__date-input theme-dark"
            @click.stop.self="openDrawer('endDate')">
        {{ formatedDateTime.endDate }}
      </span>
      <span :class="selectedClass('endTime')"
            class="date-input__hours"
            @click.stop.self="openDrawer('endTime')">
        {{ formatedDateTime.endTime }}
      </span>
    </div>
    <transition name="fade">
      <Drawer v-if="drawer.startDate || drawer.endDate || drawer.startTime || drawer.endTime"
              v-on-clickaway="away"
              :side="$q.platform.is.mobile ? 'right' : 'left'"
              posY="105px"
              class="calendar-drawer">
        <q-datetime-picker v-show="drawer.startDate"
                           v-model="timeRange.startDate"
                           :max="timeRange.endDate"
                           minimal
                           format24h
                           dark
                           color="white"
                           type="date"
                           @input="unselectTimeRange()" />
        <q-datetime-picker v-show="drawer.endDate"
                           v-model="timeRange.endDate"
                           :min="timeRange.startDate"
                           :max="today"
                           minimal
                           dark
                           format24h
                           color="white"
                           type="date"
                           @input="unselectTimeRange()" />
        <time-picker v-show="drawer.startTime"
                     v-model="timeRange.startTime"
                     :max="endDateTime"
                     :opened="drawer.startTime"
                     :date="formatedDateTime.startDate"
                     name="startTime"
                     @input="unselectTimeRange()" />
        <time-picker v-show="drawer.endTime"
                     v-model="timeRange.endTime"
                     :min="startDateTime"
                     :opened="drawer.endTime"
                     :date="formatedDateTime.endDate"
                     :max="today"
                     name="endTime"
                     @input="unselectTimeRange()" />
      </Drawer>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { directive as onClickaway } from 'vue-clickaway';

import DebounceMixin from '@/app/mixins/debounce';

import ChevronIcon from '@/app/components/ui/pictos/chevron.vue';
import Drawer from '@/app/components/ui/drawer.vue';
import TimePicker from '@/app/components/inputs/time-picker.vue';

export default {
  name: 'ControlTime',
  components: {
    ChevronIcon,
    Drawer,
    TimePicker,
  },
  directives: { onClickaway: onClickaway },
  mixins: [DebounceMixin],
  data() {
    return {
      drawer: {
        startDate: false,
        endDate: false,
        startTime: false,
        endTime: false,
      },
      timeRange: {
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
        value: undefined,
      },
      timeRangeSelected: 'day',
      today: null,
      liveMode: false,
      liveInterval: null,
      intervalDelay: 30000, //in milliseconds
    };
  },
  computed: {
    dateFrom() {
      return this.$store.state.selections.time.from;
    },
    dateTo() {
      return this.$store.state.selections.time.to;
    },
    formatedDateTime() {
      return {
        startDate: moment(this.timeRange.startDate).format('DD.MM.YY'),
        endDate: moment(this.timeRange.endDate).format('DD.MM.YY'),
        startTime: moment(this.timeRange.startTime).format('HH[H]mm'),
        endTime: moment(this.timeRange.endTime).format('HH[H]mm'),
      };
    },
    selectedClass() {
      return type => (this.drawer[type] ? 'selectedDrawer' : '');
    },
    startDateTime() {
      return moment(`${this.formatedDateTime.startDate} ${this.formatedDateTime.startTime}`, 'DD.MM.YY HH[H]mm');
    },
    endDateTime() {
      return moment(`${this.formatedDateTime.endDate} ${this.formatedDateTime.endTime}`, 'DD.MM.YY HH[H]mm');
    },
  },
  watch: {
    timeRange: {
      handler(val) {
        this.setDate();
      },
      deep: true,
    },
  },
  mounted() {
    this.today = moment().utc();
    this.setTimeRangeDate('day');
    this.$emit('input', `${this.$t('components.timeControle.day')}`);
  },
  beforeDestroy() {
    clearInterval(this.liveInterval);
  },
  methods: {
    buttonClass(range) {
      return {
        'o-button--black': range === this.timeRangeSelected,
        'o-button--hover': range !== this.timeRangeSelected,
      };
    },
    setDate() {
      this.$store.commit('selections/setTime', {
        from: this.startDateTime.format(),
        to: this.endDateTime.format(),
      });
      this.$store.commit('selections/setTimeRange', this.timeRangeSelected);
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    selectTimeRange(range) {
      this.timeRangeSelected = true;
      this.timeRange.value = range;
      clearInterval(this.liveInterval);
      if (range !== 'live') {
        this.$store.commit('selections/setTimeRange', null);
        this.setTimeRangeDate(range);
      } else {
        this.liveMode = true;
        this.$store.commit('selections/setTimeRange', 'live');
        this.fetchLiveData();
        this.liveInterval = setInterval(() => this.fetchLiveData(), this.intervalDelay);
      }
      this.timeRangeSelected = range;
      this.$emit('input', `${this.$t(`components.timeControle.${this.timeRange.value}`)}`);
    },
    setTimeRangeDate(timeRange) {
      this.liveMode = false;
      this.$store.commit('selections/setTimeRange', null);
      let startDate = moment().utc();
      if (timeRange === 'year') startDate = startDate.subtract(1, 'years');
      else if (timeRange === 'month') startDate = startDate.subtract(1, 'months');
      else if (timeRange === 'week') startDate = startDate.subtract(1, 'weeks');
      else if (timeRange === 'day') startDate = startDate.subtract(1, 'days');
      this.timeRange.startDate = startDate;
      this.timeRange.endDate = this.today;
      this.timeRange.startTime = startDate;
      this.timeRange.endTime = this.today;
    },
    unselectTimeRange() {
      if (!this.timeRangeSelected) this.resetSelectedButtons();
    },
    fetchLiveData() {
      this.today = moment().utc();
      this.$store.dispatch('oapp/fetchCurrentOappData');
      this.setTimeRangeDate('live');
    },
    away() {
      this.resetAllDrawers();
    },
    resetAllDrawers(type = null) {
      Object.keys(this.drawer).forEach(k => {
        if (!type || type !== k) this.drawer[k] = false;
      });
    },
    openDrawer(type) {
      this.timeRangeSelected = false;
      this.liveMode = false;
      clearInterval(this.liveInterval);
      if (type === 'startDate') this.drawer.startDate = !this.drawer.startDate;
      else if (type === 'endDate') this.drawer.endDate = !this.drawer.endDate;
      else if (type === 'startTime') this.drawer.startTime = !this.drawer.startTime;
      else this.drawer.endTime = !this.drawer.endTime;
      this.resetAllDrawers(type);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.control-time
  padding $space-1
  width 100%
  .button-container
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    border-radius $border-radius
    box-shadow $shadow-elevation-1
    .o-button
      flex 1
      background-color white
      text-transform uppercase
      font-weight $fw-regular
      font-size $fs-little
    .o-button--black
      font-weight $fw-bold
    .o-button--hover:hover
      background-color black
      color white
      font-weight $fw-bold
    .button-container__top
      display flex
      margin-bottom $border-medium
      .o-button
        margin-left $border-medium
        padding $space-1
        &--year
          margin-left 0
          border-top-right-radius 0
          border-bottom-right-radius 0
          border-bottom-left-radius 0
        &--month, &--week
          border-radius 0
        &--day
          border-top-left-radius 0
          border-bottom-right-radius 0
          border-bottom-left-radius 0
    .button-container__bottom
      display flex
      .o-button--live
        border-top-left-radius 0
        border-top-right-radius 0
  .date-input-container
    display flex
    justify-content center
    align-items center
    font-size $nfs-body
    .control-time__date-input
      padding $space-little
      font-weight $fw-bold
      cursor pointer
      user-select none
    .date-input__chevron
      transform rotate(-90deg)
    .date-input__hours
      padding $space-little
      color $dark
      font-weight $fw-light
      cursor pointer
      user-select none
    .selectedDrawer
      height 23px
      border-radius 4px
      background-color black
      color white
  .calendar-drawer
    position absolute
    top 23px
    left 100%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
