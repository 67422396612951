<template>
  <div class="zone-selection">
    <div class="input-container">
      <input v-model="searchInput"
             type="text"
             class="search-input o-input o-input--faded"
             :placeholder="$t('components.zoneSelection.searchPlaceholder')"
             @input="search(searchInput)">
    </div>
    <div class="parcels-list">
      <ZoneItem v-for="v in displayedParcelsSorted"
                v-if="v.spaces.length"
                :key="v.uuid"
                :zone="v"
                :active="valueIsSelected(v)"
                :subLabel="v.floor"
                chipMode
                class="zone-item"
                @mouseover="showZone(v)"
                @mouseout="hideZone(v)"
                @click="toggleValueSelect(v)" />
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import chroma from 'chroma-js';
import { mapState, mapGetters } from 'vuex';

import ZoneItem from '@/app/pages/building-configuration/components/zone-item.vue';

export default {
  name: 'ZoneSelection',
  components: { ZoneItem },
  data() {
    return {
      searchInput: '',
      oldColors: {},
      displayedParcels: [],
    };
  },
  computed: {
    ...mapState('oapps', 'oappData'),
    ...mapGetters('buildings', ['getFloorFromParcel']),
    currentWhitelistedSpaces() {
      return this.$store.getters['building/currentWhitelistedSpaces'];
    },
    selectedZones() {
      return this.$store.state.selections.zones;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    getFloorFromParcel() {
      return this.$store.getters['zones/getFloorFromParcel'];
    },
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    zones() {
      return this.$store.state.zones.collection;
    },
    buildings() {
      return this.$store.state.building.collection;
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    displayedParcelsSorted() {
      return [...this.displayedParcels].sort((a, b) => (a.floor < b.floor ? 1 : -1));
    },
    zonesList() {
      const zones = [];
      Object.keys(this.zones).forEach(buildingUuid => {
        if (this.selectedBuilding === null || (this.selectedBuilding && buildingUuid === this.selectedBuilding.uuid)) {
          this.zones[buildingUuid].forEach(z => {
            const zone = z;
            if (z.spaces.length) {
              const floor = this.getFloorFromParcel({ building: buildingUuid, spaces: z.spaces });
              if (!floor) return;
              zone['floor'] = floor.name;
              zones.push(zone);
            }
          });
        }
      });
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      zones.sort((a, b) => collator.compare(a.name, b.name));
      return zones;
    },
  },
  watch: {
    buildings(val) {
      this.$store.dispatch('zones/fetch');
    },
    'currentOapp.data'() {
      this.oldColors = [];
    },
    // filteredSelection: {
    //   handler(val) {
    //     this.checkActiveZones();
    //   },
    //   deep: true,
    // },
    searchInput(val) {
      if (!val) this.displayedParcels = this.zonesList;
    },
    zones(val) {
      this.searchInput = '';
      this.displayedParcels = this.zonesList;
    },
    selectedBuilding() {
      this.searchInput = '';
      this.displayedParcels = this.zonesList;
    },
  },
  mounted() {
    this.searchInput = '';
    this.displayedParcels = this.zonesList;
  },
  methods: {
    valueIsSelected(value) {
      return !!this.selectedZones.find(z => z.uuid === value.uuid);
    },
    toggleValueSelect(zone) {
      // get count of spaces whitelisted in zone
      const whiteListedSpaceData = zone.spaces.reduce((acc, spaceUuid) => {
        if (this.currentWhitelistedSpaces.includes(spaceUuid)) acc++;
        return acc;
      }, 0);
      if (whiteListedSpaceData === 0) return;
      this.hideZone(zone);
      if (this.valueIsSelected(zone)) this.$store.dispatch('selections/unselectZone', zone);
      else this.$store.dispatch('selections/selectZone', zone);
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    zoneNameStyle(zone) {
      const color = chroma(zone.color).hex();
      return { background: color, color: chroma(zone.color).get('lab.l') < 70 ? 'white' : 'black' };
    },
    zoneTextStyle(zone) {
      if (zone.name.length > 23)
        return `background: linear-gradient(to right, ${
          chroma(zone.color).get('lab.l') < 70 ? 'white' : 'black'
        } 80%, transparent); color: transparent;`;
      return '';
    },
    spaceZoneName(zone) {
      if (zone) return zone.name.slice(0, 23);
      return '';
    },
    showZone(zone) {
      if (!this.selectedBuilding) return;
      zone.spaces.forEach(s => {
        const space = this.$palmier.getSpace(s);
        if (space) {
          this.oldColors[s] = space.style.fill;
          space.style.fill = zone.color;
        }
      });
    },
    hideZone(zone) {
      if (!this.selectedBuilding) return;
      zone.spaces.forEach(s => {
        const space = this.$palmier.getSpace(s);
        if (space && s in this.oldColors) space.style.fill = this.oldColors[s];
        delete this.oldColors[s];
      });
    },
    // checkActiveZones() {
    //   const toRemove = [];
    //   this.selectedZones.forEach(z => {
    //     const zonePresent = z.spaces.every(s => {
    //       let spacePresent = false;
    //       Object.keys(this.filteredSelection).forEach(b => {
    //         Object.keys(this.filteredSelection[b]).forEach(f => {
    //           const index = this.filteredSelection[b][f].findIndex(sp => sp.idIFC === s);
    //           if (index !== -1) spacePresent = true;
    //         });
    //       });
    //       return spacePresent;
    //     });
    //     if (!zonePresent) toRemove.push(z);
    //   });
    // toRemove.forEach(z => this.$store.dispatch('selections/unselectZone', { uuid: z.uuid }));
    // },
    search(terms) {
      const fuse = new Fuse(this.zonesList, {
        shouldSort: true,
        threshold: 0.2,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['type', 'name', 'floor'],
      });
      const found = fuse.search(terms);
      this.displayedParcels = found;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.zone-selection
  position relative
  display flex
  flex-direction column
  padding $space-2 0
  padding-bottom 0
  width 100%
  .input-container
    margin-bottom $space-2
    padding 0 $space-1
    .search-input
      width 100%
  .parcels-list
    position relative
    display flex
    flex-direction column
    overflow-y auto
    padding 0 $space-1
    .zone-item
      flex-shrink 0
      margin-bottom $space-little
      &:last-child
        margin-bottom $space-2
</style>
