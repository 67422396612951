<template>
  <div class="sidebar-in">
    <SidebarItem :collapsed="collapsed.time"
                  class="t_sidebar-in--time"
                 :title="$t('pages.data.sidebarIn.time')"
                 :subText="timeRange"
                 @expand="val => closeOtherItems('time', val)">
      <TimeControl @input="val => timeRange=val"/>
    </SidebarItem>
    <SidebarItem :collapsed="collapsed.building"
                  class="t_sidebar-in--buildings"
                 :title="$t('pages.data.sidebarIn.buildings')"
                 :subText="buildingSubText"
                 @expand="val => closeOtherItems('building', val)">
      <BuildingSelection @input="val => selectedBuilding=val"/>
    </SidebarItem>
    <SidebarItem :collapsed="collapsed.filters"
                  class="t_sidebar-in--filters"
                 :title="$t('pages.data.sidebarIn.filters')"
                 :subText="filterSubText"
                 :isTooltip="true"
                 :actionButton="$t('components.filterSelection.add')"
                 @onClickActionButton="addFilter"
                 @expand="val => closeOtherItems('filters', val)">
      <FilterSelection ref="filterSelection"
                       @input="val => selectedFilters=val" />
    </SidebarItem>
    <SidebarItem v-if="user && user.featuresRights.parcel.READ"
                  class="t_sidebar-in--parcels"
                 :collapsed="collapsed.parcels"
                 :title="$t('pages.data.sidebarIn.parcels')"
                 @expand="val => closeOtherItems('parcels', val)">
      <ZoneSelection />
    </SidebarItem>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SidebarItem from '@/app/components/layout/sidebar/sidebar-item.vue';
import TimeControl from '@/app/pages/data/components/time.vue';
import BuildingSelection from '@/app/components/buildings/building-selection.vue';
import BuildingSelectionMixin from '@/app/mixins/building-selection';
import FilterSelection from '@/app/pages/data/components/filter-selection.vue';
import ZoneSelection from '@/app/pages/data/components/zone-selection.vue';

export default {
  name: 'SidebarIn',
  components: {
    SidebarItem,
    TimeControl,
    BuildingSelection,
    FilterSelection,
    ZoneSelection,
  },
  mixins: [BuildingSelectionMixin],
  props: {
    sidebarState: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapsed: {
        time: true,
        building: true,
        filters: true,
        parcels: true,
      },
      timeRange: null,
      selectedFilters: {},
    };
  },
  computed: {
    ...mapState('ui', {
      sidebarOutState: state => state.home.sidebarOut,
    }),
    user() {
      return this.$store.state.user.user;
    },
    buildingSubText() {
      const buildings = this.$store.state.building.collection;
      const selectedBuilding = buildings.filter(building => this.isBuildingSelected(building)).map(building => building.name);
      if (selectedBuilding.length === 0) return '';
      return selectedBuilding.join(', ');
    },
    filterSubText() {
      let type = '';
      let access = '';
      if (this.selectedFilters.type && this.selectedFilters.type.length !== 0) {
        const translatedTypes = this.selectedFilters.type.map(type => this.$t(`pages.buildingConfiguration.properties.type.${type}`));
        type = `TYPE: ${translatedTypes.join(', ')}`;
      }
      if (this.selectedFilters.access && this.selectedFilters.access.length !== 0) {
        const translatedAccess = this.selectedFilters.access.map(access => this.$t(`pages.buildingConfiguration.properties.type.${access}`));
        access = `ACCESS: ${translatedAccess.join(', ')}`;
      }
      return `${type} ${access}`;
    }
  },
  watch: {
    largeView(val) {
      Object.keys(this.collapsed).forEach(c => (this.collapsed[c] = val));
    },
  },
  methods: {
    addFilter() {
      // const sidebarItemFilter = this.$children[2]; // third sidebar item
      // const filterSelectionComp = sidebarItemFilter.$children[0]; // filter selection component
      // filterSelectionComp.toggleDrawer();
      this.$refs.filterSelection.toggleDrawer();
    },
    closeOtherItems(item, expanded) {
      this.collapsed[item] = !expanded;
      Object.keys(this.collapsed).forEach(c => {
        if (c !== item) this.collapsed[c] = true;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.sidebar-in
  position relative
  display flex
  flex-direction column
  height 100%
  .sidebar-item:last-child
    border-bottom none
  .sidebar-in__actions
    display flex
    justify-content flex-end
    margin-top auto
    padding $space-1
    .action
      display flex
      justify-content center
      align-items center
      padding $space-little
      background-color $light
      box-shadow $shadow-elevation-1
      cursor pointer
      transition box-shadow 0.3s ease
      &:hover
        box-shadow $shadow-elevation-2
      .action__icon
        transform rotate(90deg)
        fill $grey
</style>
