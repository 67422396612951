<template>
  <div :class="{'selection-info--extended': oappSidebarExpanded}"
       class="selection-info">
    <div :class="{'data-info--extended': oappSidebarExpanded}"
         class="data-info">
      <span :class="{'data-info__values--extended': oappSidebarExpanded}"
            class="data-info__values">
        <span class="t_info__values--buildings">{{ selectionInfo.buildings }}</span>
        <span class="t_info__values--floors">{{ selectionInfo.floors }}</span>
        <span class="t_info__values--spaces">{{ selectionInfo.spaces }}</span>
        <span class="t_info__values--surfaces">{{ selectionInfo.surfaces.toFixed(0) }}</span>
        <span class="t_info__values--sensors">{{ selectionInfo.sensors }}</span>
      </span>
      <span :class="{'data-info__labels--extended': oappSidebarExpanded}"
            class="data-info__labels">
        <span>{{$tc('components.selectionInfo.buildings', selectionInfo.buildings)}}</span>
        <span>{{$tc('components.selectionInfo.floors', selectionInfo.floors)}}</span>
        <span>{{$tc('components.selectionInfo.spaces', selectionInfo.spaces)}}</span>
        <span>m<sup>2</sup></span>
        <span>{{$tc('components.selectionInfo.sensors', selectionInfo.sensors)}}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectionInfo',
  computed: {
    oappSidebarExpanded() {
      return this.$store.state.oapp.sidebarExpanded;
    },
    selectionInfo() {
      return this.$store.getters['selections/info'];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.selection-info
  position relative
  flex 2
  user-select none
  &--extended
    display flex
  .data-info
    display flex
    &--extended
      margin-right 30px
    .data-info__values, .data-info__labels
      display flex
      flex-direction column
      justify-content flex-start
      font-size $fs-h3
    .data-info__values
      display flex
      justify-content center
      text-align right
      font-weight 700
    .data-info__labels
      display flex
      flex 6
      justify-content center
      margin-left 8px
      letter-spacing 0.5px
      &--extended
        padding-right 30px
  .selection__title
    position absolute
    top 0
    right $space-2
    height 100%
    content ''
    text-align center
    letter-spacing 1.5px
    writing-mode vertical-rl
    text-orientation sideways-left
</style>
