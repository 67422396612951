<template>
  <div>
    <div v-show="selectedTab.length"
         class="clickaway-helper--header"
         @click="closeTabs" />
    <div v-show="selectedTab.length"
         class="clickaway-helper"
         @click="closeTabs" />

    <div :class="navTabsClass"
         class="nav-tabs">
      <div v-touch-swipe="swipeHandler"
           class="row justify-center items-center tabs">
        <div :class="selectedTab === 'tab-time' ? 'tab-selected': ''"
             class="col text-center tab"
             @click="selectedTab = 'tab-time'">
          TIME
        </div>
        <div :class="selectedTab === 'tab-building' ? 'tab-selected': ''"
             class="col text-center tab"
             style="padding: 10px"
             @click="selectedTab = 'tab-building'">
          BUILDING
        </div>
        <div :class="selectedTab === 'tab-filter' ? 'tab-selected': ''"
             class="col text-center tab"
             @click="selectedTab = 'tab-filter'">
          FILTER
        </div>
        <div :class="selectedTab === 'tab-oapps' ? 'tab-selected': ''"
             class="col text-center tab tab-black"
             @click="selectedTab = 'tab-oapps'">
          APP
        </div>
      </div>
      <div :style="tabsFull ? 'height: 100%;' : ''"
           class="row">
        <div v-show="selectedTab === 'tab-time'"
             :class="tabsFull ? 'tab-content-full' : ''"
             class="tab-content">
          <time-control />
        </div>
        <div v-show="selectedTab === 'tab-building'"
             :class="tabsFull ? 'tab-content-full' : ''"
             class="tab-content">
          <building-selection />
        </div>
        <div v-show="selectedTab === 'tab-filter'"
             :class="tabsFull ? 'tab-content-full' : ''"
             class="tab-content">
          FILTER TAB
        </div>
        <div v-show="selectedTab === 'tab-oapps'"
             :class="tabsFull ? 'tab-content-full' : ''"
             class="tab-content no-padding">
          <div slot="oapps">
            <OappItem v-for="oapp in installedOapps"
                      :id="oapp.id"
                      :key="oapp.id"
                      :name="oapp.name"
                      :icon="oapp.icon"
                      :color="oapp.color"
                      :limits="limits"
                      :value="isCurrentOapp(oapp.id) ? oappsValues[oapp.id] : {label: '', value: null}"
                      :type="oapp.type"
                      :collapsed="isCurrenOapp(oapp.id)"
                      :class="'oapp-item ' + oappClass(oapp.id)"
                      @oappCollapsed="closeItemEvent()">
              <component v-model="oappsValues[oapp.id]"
                         :is="oapp.component"
                         :oapp="oapp"
                         :timeRange="timeRange"
                         :means="currentOapp.data && isCurrentOapp(oapp.id) ? currentOapp.data.global : undefined"
                         :graphAVG="currentOapp.data && isCurrentOapp(oapp.id) ? currentOapp.data.graphAVG: undefined"
                         :timelineMean="currentOapp.data && isCurrentOapp(oapp.id) ? currentOapp.data.timeline: undefined"
                         :liveMode="timeRangeIsLive"
                         :selected="Object.keys(filteredSelection)"
                         :sidebarExpanded="isCurrentOapp(oapp.id) && oappSidebarExpanded" />
            </OappItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeControl from '@/app/pages/data/components/time.vue';
import BuildingSelection from '@/app/components/buildings/building-selection.vue';
import OappItem from '@/app/pages/data/components/oapp-item.vue';

export default {
  name: 'NavigationTabs',
  components: { TimeControl, BuildingSelection, OappItem },
  data() {
    return {
      oappsValues: {},
      selectedTab: '',
      obj: null,
      tabsFull: false,
      tabsOpened: false,
    };
  },
  computed: {
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    fetching() {
      return this.$store.state.oapp.fetching;
    },
    oappSidebarExpanded() {
      return this.$store.state.oapp.sidebarExpanded;
    },
    timeRangeIsLive() {
      return this.$store.state.selections.time.range === 'live';
    },
    navTabsClass() {
      return {
        'nav-tabs-opened': this.selectedTab.length,
        'nav-tabs-full': this.tabsFull,
      };
    },
    oappArray() {
      return this.$store.getters['oapp/installed'].filter(oapp => !oapp.hidden);
    },
    timeRange() {
      return {
        dateFrom: this.$store.state.selections.time.from,
        dateTo: this.$store.state.selections.time.to,
      };
    },
    limits() {
      if (!this.currentOapp || !this.currentOapp.data || !this.currentOapp.type) return { min: 0, max: 0 };
      return {
        min: this.currentOapp.limits[this.currentOapp.type].min,
        max: this.currentOapp.limits[this.currentOapp.type].max,
      };
    },
  },
  watch: {
    selectedTab() {
      if (this.selectedTab !== '') this.tabsOpened = true;
      else this.tabsOpened = false;
      if (this.tabsOpened && (this.selectedTab === 'tab-time' || this.selectedTab === 'tab-oapps')) this.tabsFull = true;
      else this.tabsFull = false;
    },
    tabsOpened() {
      this.$store.dispatch('ui/toggleNavTabs');
    },
  },
  methods: {
    isCurrentOapp(oappId) {
      return this.currentOapp && this.currentOapp.id === oappId;
    },
    swipeHandler(obj) {
      this.tabsFull = obj.direction === 'up' && this.tabsOpened;
    },
    closeTabs() {
      this.selectedTab = '';
      this.tabsFull = false;
    },
    oappClass(id) {
      return this.currentOapp && this.currentOapp !== id ? 'oapp-item-hidden' : '';
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.nav-tabs
  position fixed
  bottom -250px
  left 0
  z-index 3000
  min-height 300px
  width 100%
  transition all 0.2s ease

.nav-tabs-opened
  bottom 0 !important
  transition all 0.2s ease

.nav-tabs-full
  top 35px !important
  height 100%

.tabs
  z-index 1000
  width 100%
  height 50px
  background-color white
  .tab
    display flex
    justify-content center
    align-items center
    height 100%
    background-image linear-gradient(to right, $silver, #f7f3ef 50%)
    color #464749
    font-weight 700
    font-size $fs-h2
  .tab-black
    background-color black
    background-image none
    color white
  .tab-selected
    background-color white
    background-image none
    color black

.tab-content
  z-index 1000
  overflow auto
  overflow-x hidden
  padding-top 20px
  width 100%
  height 250px
  background-color white
  .oapp-item
    visibility visible
    max-height 2000px
    opacity 1
    transition max-height 0.5s cubic-bezier(1, 0, 1, 0) 0s, visibility 0s linear 0s, opacity 0.2s linear 0.5s
  .oapp-item-hidden
    visibility hidden
    max-height 0px
    opacity 0
    transition max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s

.tab-content-full
  height 100%

.clickaway-helper, .clickaway-helper--header
  position fixed
  top 0
  bottom 0
  z-index 700
  width 100%
  height 100%
  background-color transparent

.clickaway-helper--header
  z-index 2000
  height 35px
</style>
